<ng-container *ngIf="isFreeTier">
    <ng-template #freeTierMessageTemplate>
        <h3>From only $99/month</h3>
        <p>{{ 'schedules_upgrade_message' |myLbTranslate }}</p>
    </ng-template>
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="schedules_upgrade_title"
        [messageTemplate]="freeTierMessageTemplate"
        [mockTableHeaders]="mockTableHeaders"
        imagePath="assets/centralisedManagement/empty-state/schedules.svg"
        buttonOneText="contact_sales"
        buttonOneClass="primary"
        [buttonOneCallback]="contactSales.bind(this)"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-container *ngIf="isLoading else pageContent">
    <div class="page-loader"></div>
</ng-container>
<ng-template #pageContent>
    <ng-container *ngIf="!tableRows.length else table">
        <app-alternative-state-page
            header="schedules"
            titleText="schedules_empty_title"
            messageHtml="{{ 'schedules_empty_message' | myLbTranslate }}"
            [mockTableHeaders]="mockTableHeaders"
            imagePath="assets/centralisedManagement/empty-state/schedules.svg"
            buttonOneText="schedule_add"
            buttonOneLink="/cm/scheduler/schedules/new">
        </app-alternative-state-page>
    </ng-container>
    <ng-template #table>
        <div class="component-main">
            <div class="content-space-between">
                <div class="content-left">
                    <img ngSrc="assets/centralisedManagement/icons/schedule.svg" alt="adc icon" height="20" width="20">
                    <h1 id="component-title">{{ 'schedules' | myLbTranslate }}</h1>
                </div>
                <div class="content-right">
                    <button
                        id="schedule-list-add-new"
                        [disabled]="!permissionsLookup['schedule-list-add-new']"
                        routerLink="new"
                        class="primary">
                        {{ 'schedule_add' |myLbTranslate }}
                    </button>
                </div>
            </div>

            <app-table
                id="schedules-list"
                [data]="tableRows"
                [allowMultiSelect]="false"
                [showPaginator]="false"
                [stickyHeader]="true"
                [searchTerm]="searchTerm">
                <app-column [flexGrow]="1" prop="productType" label="product_type"></app-column>
                <app-column [flexGrow]="1" prop="productName" label="product_name"></app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="adcStatus"
                    label="adc_running_status"
                    cellAlignment="left"
                    [cellTemplate]="runningStatus">
                    <ng-template let-row #runningStatus>
                        <img
                            [src]="getRunningStatusSrc(row)"
                            [class]="getAdcStatusClassName(row)"
                            alt="Running Status"
                            [title]="row.adcStatus"/>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="scheduleType" label="scheduled_type"></app-column>
                <app-column [flexGrow]="1.25" prop="scheduleTime" label="scheduled_date_time"></app-column>
                <app-column [flexGrow]="1" prop="occurrenceName" label="occurrence"></app-column>
                <app-column [flexGrow]="1" prop="status" label="status" [cellTemplate]="status">
                    <ng-template let-row #status>
                        <span class="status-bullet {{row.status}}"></span>
                        <span>{{ row.status | titlecase }}</span>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="actions" label="" cellAlignment="right"
                            [cellTemplate]="actions">
                    <ng-template let-row #actions>
                        <app-action-dots>
                            <button
                                *ngIf="row.scheduleType === 'backup'"
                                id="schedule-list-view-backups"
                                [disabled]="!permissionsLookup['schedule-list-view-backups']"
                                [routerLink]="'/cm/storage/backups/' + row.productId">
                                {{ 'adc_backups_view_list' | myLbTranslate }}
                            </button>
                            <button
                                *ngIf="row.status !== 'completed'"
                                id="schedule-list-backup-update-adc"
                                [disabled]="!permissionsLookup['schedule-list-backup-update-adc'] || row.adcStatus !== 'online'"
                                (click)="runTask(row)">
                                {{ (row.scheduleType === 'backup' ? 'adc_backup_now' : 'adc_update_now') | myLbTranslate }}
                            </button>
                            <!-- TODO waiting for API updates, currently not working at the other end -->
                            <button
                                *ngIf="row.status !== 'completed'"
                                id="schedule-list-edit-schedule"
                                [disabled]="!permissionsLookup['schedule-list-edit-schedule'] || true"
                                (click)="editSchedule(row)">
                                {{ 'schedule_edit' | myLbTranslate }}
                            </button>
                            <button
                                id="schedule-list-delete-schedule"
                                [disabled]="!permissionsLookup['schedule-list-delete-schedule']"
                                (click)="confirmDeleteSchedule(row)">
                                {{ 'schedule_delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </div>
    </ng-template>
</ng-template>
