<ng-container *ngIf="selectedVendorId else noVendor">
    <div class="component-main">
        <div class="content-space-between">
            <div class="content-left">
                <img
                    ngSrc="/assets/centralisedManagement/icons/{{selectedSupplier?.icon}}-colour.svg"
                    height="24"
                    width="24"
                    alt="{{selectedSupplier?.title}} Icon">
                <h1 id="component-title">{{ selectedSupplier?.title }}</h1>
            </div>
        </div>
        <div class="content page-wrapper">
            <div class="content-half-width">
                <form [formGroup]="formGroup" class="process-container" [disableFocus]="true">
                    <div
                        class="step"
                        [class.active]="activeStep === 1"
                        [class.complete]="activeStep > 1"
                        formGroupName="step1">
                        <h3>{{ 'connection_method' | myLbTranslate }}</h3>
                        <div class="step-content">
                            <ng-container *ngIf="activeStep === 1">
                                <div class="form-group">
                                    <div class="form-content">
                                        <app-dropdown
                                            dropDownId="shuttle"
                                            [formGroup]="getFormGroup('step1')"
                                            formControlName="connectionMethod"
                                            [content]="dropdown.connectionMethod">
                                        </app-dropdown>
                                    </div>
                                </div>
                                <ng-container *ngIf="formGroup.get('step1.connectionMethod').value === 'token'">
                                    <ng-container *ngIf="selectedVendorId === 7">
                                        <div class="icon-header-wrapper">
                                            <img
                                                ngSrc="assets/centralisedManagement/icons/token_linux.svg"
                                                alt="linux"
                                                height="19"
                                                width="21">
                                            <h5>{{ 'adc_shuttle_token_linux' | myLbTranslate }}</h5>
                                        </div>
                                        <p>{{ 'adc_shuttle_token_linux_message' | myLbTranslate }}</p>
                                        <div class="icon-header-wrapper">
                                            <img
                                                ngSrc="assets/centralisedManagement/icons/token_requirements.svg"
                                                alt="linux"
                                                height="19"
                                                width="21">
                                            <h5>{{ 'adc_shuttle_token_requirements' | myLbTranslate }}</h5>
                                        </div>
                                        <p>{{ 'adc_shuttle_token_requirements' | myLbTranslate }}</p>
                                        <ul>
                                            <li>Curl</li>
                                        </ul>
                                    </ng-container>
                                    <div class="icon-header-wrapper">
                                        <img
                                            ngSrc="assets/centralisedManagement/icons/token_token.svg"
                                            alt="linux"
                                            height="19"
                                            width="21">
                                        <h5>{{ 'token' | myLbTranslate }}</h5>
                                    </div>
                                    <p>{{ (selectedVendorId === 7 ? 'adc_shuttle_token_message_standalone' : 'adc_shuttle_token_message') | myLbTranslate }}</p>
                                    <div class="token-holder">
                                        {{ token }}
                                        <button
                                            appCopyClipboard
                                            class="copy-icon"
                                            [updateButtonText]=false
                                            [textToCopy]="token"
                                            (click)="copyNextStep()">
                                        </button>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="activeStep > 1">
                                <div class="set-content">
                                <span>
                                    <strong>{{ 'method' | myLbTranslate }}:</strong>
                                    {{ formGroup.get('step1.connectionMethod').value }}
                                </span>
                                    <ng-container *ngIf="formGroup.get('step1.connectionMethod').value === 'token'">
                                        <p class="token-selected">{{ token }}</p>
                                    </ng-container>
                                    <span>
                                    <button class="button-as-link quinary right-arrow" (click)="setStep(1)">
                                        {{ 'edit' | myLbTranslate }}
                                    </button>
                                </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div
                        class="step"
                        [class.active]="activeStep === 2"
                        [class.complete]="activeStep > 2"
                        formGroupName="step2">
                        <h3>{{ 'adc_shuttle_waiting' | myLbTranslate }}</h3>
                        <div class="step-content">
                            <ng-container *ngIf="activeStep === 2">
                                <ng-container *ngIf="selectedVendorId === 7 else credentials">
                                    <p>{{ 'shuttle_install_message_token' | myLbTranslate }}</p>
                                </ng-container>
                                <ng-template #credentials>
                                    <p>{{ 'shuttle_install_message_credentials' |myLbTranslate }}</p>
                                </ng-template>
                                <div class="loading-wrapper">
                                    <div class="loader"></div>
                                    <span>{{ 'shuttle_install_wait' |myLbTranslate }}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="step" [class.active]="activeStep === 3">
                        <h3>{{ 'adc_shuttle_adoption' | myLbTranslate }}</h3>
                        <div class="step-content">
                            <ng-container *ngIf="activeStep === 3">
                                <div class="success-wrapper">
                                    <span>{{ 'adc_shuttle_incoming_message' | myLbTranslate }}</span>
                                    <span class="note"> {{ 'adc_shuttle_incoming_message_2' | myLbTranslate }}</span>
                                    <ng-container *ngFor="let shuttle of newlyAddedShuttles">
                                        <div class="token-holder">
                                            <table class="registered-shuttles">
                                                <tr>
                                                    <th>{{ 'name' | myLbTranslate }}</th>
                                                    <td class="right">{{ shuttle?.label }}</td>
                                                </tr>
                                                <tr>
                                                    <th>{{ 'address' | myLbTranslate }}</th>
                                                    <td class="right">{{ shuttle?.ipAddress }}</td>
                                                </tr>
                                                <tr>
                                                    <th>{{ 'port' | myLbTranslate }}</th>
                                                    <td class="right">{{ shuttle?.port }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="button-cell" colspan="2">
                                                        <app-loading-button
                                                            buttonId="adcs-shuttle-adopt"
                                                            buttonText="adopt"
                                                            buttonClass="primary"
                                                            [isDisabled]="!permissionsLookup['adcs-shuttle-adopt']"
                                                            [callBack]="adoptShuttle.bind(this, shuttle)">
                                                        </app-loading-button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </ng-container>
                                    <span>{{ 'adc_shuttle_incoming_message_3' | myLbTranslate }} </span>
                                    <button
                                        class="button-as-link"
                                        (click)="contactSupport()">
                                        {{ 'contact_us' | myLbTranslate | titlecase }}
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="component-footer">
        <button *ngIf="!shuttleAttached" class="outline" routerLink="/cm/adcs/shuttle/add">
            {{ 'cancel' |myLbTranslate }}
        </button>
        <button
            id="adc-new-back"
            *ngIf="!shuttleAttached"
            class="primary"
            [disabled]="isBackButtonDisabled()"
            (click)="backStep()">
            {{ 'back' | myLbTranslate }}
        </button>
        <button
            *ngIf="!shuttleAttached"
            id="shuttle-add-next"
            class="primary"
            [disabled]="(activeStep === 2 && !shuttleAttached)  || isNextButtonDisabled()"
            (click)="nextStep()">
            {{ 'next' | myLbTranslate }}
        </button>
        <button
            *ngIf="shuttleAttached"
            id="shuttle-add-done"
            class="primary"
            routerLink="/cm/adcs/shuttle">
            {{ 'adc_shuttle_button_message' | myLbTranslate }}
        </button>
    </div>
</ng-container>
<ng-template #noVendor>
    <div class="component-main">
        <div class="content-space-between">
            <h1 id="component-no-title">{{ 'shuttle_no_vendor_title' | myLbTranslate }}</h1>
        </div>
        <div class="content page-wrapper">
            <p>{{ 'shuttle_no_vendor_message' | myLbTranslate }}</p>
        </div>
    </div>
</ng-template>
