<ng-container *ngIf="!storedShuttles.length else table">
    <ng-container *ngIf="isLoading else emptyStates">
        <div class="page-loader"></div>
    </ng-container>
    <ng-template #emptyStates>
        <app-shuttle-vendor-selection [isEmptyState]="true"></app-shuttle-vendor-selection>
    </ng-template>
</ng-container>
<ng-template #table>
    <div class="component-main">
        <div class="content-space-between">
            <div class="content-left">
                <img ngSrc="assets/centralisedManagement/icons/shuttle.svg" alt="shuttle icon" height="26" width="26">
                <h1
                    id="component-title"
                    data-testid="component-title">
                    {{ 'adc_shuttle_management' | myLbTranslate }}
                </h1>
            </div>
            <div class="content-right">
                <button
                    id="adcs-shuttle-add-shuttle"
                    data-testid="adcs-shuttle-add-shuttle"
                    [disabled]="!permissionsLookup['adcs-shuttle-add-shuttle']"
                    routerLink="/cm/adcs/shuttle/add"
                    class="primary">
                    {{ 'adc_shuttle_add' | myLbTranslate }}
                </button>
            </div>
        </div>
        <app-table
            id="shuttle-list"
            [data]="tableRows"
            [allowMultiSelect]="false"
            [ignoreSearchKeys]="ignoreSearchKeys"
            [searchTerm]="searchTerm"
            [showPaginator]="false"
            [stickyHeader]="true"
            (selectedRowsOut)="openDrawer($event[0])">
            <app-column
                label="adc_shuttle_name"
                prop="label"
                [flexGrow]="1"
                [cellTemplate]="shuttleNameTemplate">
                <ng-template let-row #shuttleNameTemplate>
                    <img
                        *ngIf="row.vendor > -1"
                        class="company-logo"
                        src="assets/centralisedManagement/icons/{{row.vendorIcon}}-colour.svg"
                        alt="{{row.vendorName}} Logo"
                        title="{{row.vendorName}}"/>
                    <span class="routerLink">{{ row.label }}</span>
                </ng-template>
            </app-column>
            <app-column
                label="adc_running_status"
                prop="runningStatus"
                [flexGrow]="0.75"
                [cellTemplate]="shuttleRunningStatusTemplate">
                <ng-template let-row #shuttleRunningStatusTemplate>
                    <img
                        [src]="getRunningStatusSrc(row)"
                        class="{{row.runningStatus}}"
                        alt="Running Status"
                        [title]="row.runningStatus"/>
                </ng-template>
            </app-column>
            <app-column [flexGrow]="1" prop="ipAddress" label="ip_address"></app-column>
            <app-column
                [flexGrow]="1"
                prop="associatedAdcs"
                label="adc_shuttle_associated"
                [cellTemplate]="associatedShuttleRowsTemplate">
                <ng-template let-row #associatedShuttleRowsTemplate>
                    <ng-container *ngIf="row.associatedAdcs?.length">
                        <app-tooltip
                            [width]="432"
                            [trigger]="getAssociatedMessage(row)">
                            <table class="lb-small-table">
                                <thead>
                                <tr>
                                    <th>{{ 'label' | myLbTranslate }}</th>
                                    <th>{{ 'ip_address' | myLbTranslate }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of row.associatedAdcs | slice:0:5;">
                                    <td>{{ item.label }}</td>
                                    <td>{{ item.ipAddress }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div *ngIf="row.associatedAdcs?.length > 5" class="content-center associated-adcs">
                                <button class="primary" (click)="showAssociatedModal(row)">
                                    {{ 'more' | myLbTranslate }}
                                </button>
                            </div>
                        </app-tooltip>
                    </ng-container>
                </ng-template>
            </app-column>
            <app-column [flexGrow]="0.5" prop="namespace" label="namespace" [cellTemplate]="shuttleNamespace">
                <ng-template let-row #shuttleNamespace>
                    <ng-container *ngIf="row?.adoptionStatus !== 'pending'">
                        <span>{{ row?.namespace }}</span>
                    </ng-container>
                </ng-template>
            </app-column>
            <app-column
                [flexGrow]="0.25"
                prop="locked"
                label=""
                disableSort
                [cellTemplate]="shuttleLockedTemplate">
                <ng-template let-row #shuttleLockedTemplate>
                    <ng-container *ngIf="row.locked">
                        <img ngSrc="assets/shared/icons/locked.svg" alt="locked" height="20" width="19">
                    </ng-container>
                </ng-template>
            </app-column>
            <app-column
                [flexGrow]="1.25"
                prop="actions"
                label=""
                [cellTemplate]="shuttleActionDots"
                cellAlignment="right">
                <ng-template let-row #shuttleActionDots>
                    <ng-container
                        *ngIf="row.adoptionStatus === 'adopted' || row?.adoptionStatus === 'local' else other"
                        [ngTemplateOutlet]="actionDots"
                        [ngTemplateOutletContext]="{row, isDrawer: false}">
                    </ng-container>
                    <ng-template #other>
                        <ng-container *ngIf="row.adoptionStatus === 'pending' else adopting">
                            <!-- TODO - This has been temporarily removed as there is an ongoing issue with the backend service -->

                            <!--                            <button-->
                            <!--                                id="modal-confirm-user-password"-->
                            <!--                                class="primary"-->
                            <!--                                [disabled]="!userCanEdit"-->
                            <!--                                (click)="beginAdoptionHelper(row)">-->
                            <!--                                {{ 'adopt' | myLbTranslate }}-->
                            <!--                            </button>-->
                            <app-loading-button
                                buttonId="adcs-shuttle-adopt"
                                buttonClass="primary"
                                buttonText="adopt"
                                [handleLoadingStateExternally]="true"
                                [isDisabled]="!permissionsLookup['adcs-shuttle-adopt']"
                                [callBack]="adoptShuttle.bind(this, row)">
                            </app-loading-button>
                        </ng-container>
                        <ng-template #adopting>
                            <div class="loader"></div>
                            <span class="status-message">{{ 'adopting' | myLbTranslate }}</span>
                        </ng-template>
                        <img
                            id="adcs-shuttle-remove"
                            data-testid="adcs-shuttle-remove"
                            [class.disabled]="!removeButtonEnabled(row)"
                            class="table-row-item"
                            ngSrc="./assets/shared/icons/close-cross.svg"
                            height="12"
                            width="12"
                            alt="{{ 'adc_remove' | myLbTranslate }}"
                            (click)="confirmRemoveShuttle(row)"/>
                    </ng-template>
                </ng-template>
            </app-column>
        </app-table>
    </div>
</ng-template>

<ng-template #ShuttleInfo>
    <div *ngIf="templateContent" id="drawer-template">
        <ng-container
            *ngIf="templateContent.adoptionStatus !== 'adopted' && templateContent.adoptionStatus !== 'local'">
            <div class="button-group">
                <app-loading-button
                    buttonId="adcs-shuttle_adopt"
                    buttonClass="primary"
                    buttonText="adopt"
                    [isDisabled]="!permissionsLookup['adcs-shuttle-adopt']"
                    [callBack]="adoptShuttle.bind(this, templateContent)">
                </app-loading-button>
                <app-loading-button
                    buttonId="adcs-shuttle_shuttle-remove"
                    buttonClass="outline"
                    buttonText="adc_shuttle_remove"
                    [isDisabled]="!removeButtonEnabled(templateContent)"
                    [callBack]="confirmRemoveShuttle.bind(this, templateContent)">
                </app-loading-button>
            </div>
        </ng-container>
        <app-tab-container tabsAlignment="center">
            <app-tab tabTitle="overview" id="overview">
                <table class="flex-table">
                    <tr>
                        <th>{{ 'adc_locked' | myLbTranslate }}</th>
                        <td class="right">
                            <img
                                class="drawer-image"
                                src="assets/shared/icons/{{getLockedStateIcon(templateContent.locked)}}.svg"
                                alt="Locked"/>
                        </td>
                    </tr>
                    <tr *ngIf="templateContent.vendor > -1">
                        <th>{{ 'vendor' | myLbTranslate }}</th>
                        <td class="right">
                            <img
                                class="drawer-image"
                                src="assets/centralisedManagement/icons/{{templateContent.vendorIcon}}-colour.svg"
                                alt="{{templateContent.vendorName}} Logo"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'adoption' | myLbTranslate }} {{ 'status' | myLbTranslate }}</th>
                        <td class="right">{{ (templateContent.adoptionStatus || '-') | sentenceCase }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'address' | myLbTranslate }}</th>
                        <td class="right">{{ templateContent.ipAddress }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'port' | myLbTranslate }}</th>
                        <td class="right">{{ templateContent.port }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'namespace' | myLbTranslate }}</th>
                        <td class="right">{{ templateContent.namespace }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'version' | myLbTranslate }}</th>
                        <td class="right">{{ templateContent.version }}</td>
                    </tr>
                </table>
                <span class="notes"><strong>{{ 'notes' | myLbTranslate }}</strong></span>
                <ng-container *ngIf="templateContent.notes else noNotes">
                    <p>{{ templateContent.notes }}</p>
                </ng-container>
                <ng-template #noNotes>
                    <p class="no-data">{{ 'adc_data_no_notes' | myLbTranslate }}</p>
                </ng-template>
                <span class="tags"><strong>{{ 'tags' | myLbTranslate }}</strong></span>
                <ng-container *ngIf="templateContent.tags else noTags">
                    <app-adc-tags [editMode]="false" [tags]="templateContent.tags"></app-adc-tags>
                </ng-container>
                <ng-template #noTags>
                    <p class="no-data">{{ 'adc_data_no_tags' | myLbTranslate }}</p>
                </ng-template>
            </app-tab>
            <app-tab tabTitle="subnets" id="subnets-table">
                <table class="flex-table">
                    <tr>
                        <th>
                            <div class="content-left no-padding">
                                <img
                                    ngSrc="assets/shared/icons/network.svg"
                                    alt="subnets"
                                    height="20"
                                    width="20"/>
                                <span>{{ 'subnets' | myLbTranslate }} -
                                    <a
                                        class="default"
                                        [routerLink]="['/cm/adcs/shuttle/edit/' + templateContent.id]"
                                        [state]="{ tabFilter: 'subnets' }">
                                           {{ 'view' | myLbTranslate }}
                                    </a>
                                </span>
                            </div>
                        </th>
                        <td class="right"></td>
                    </tr>
                </table>

                <table class="flex-table">
                    <tr>
                        <th>{{ 'cidr' | myLbTranslate }}</th>
                        <th>{{ 'gateway' | myLbTranslate }}</th>
                    </tr>
                    <ng-container *ngFor="let subnet of templateContent.subnets">
                        <tr>
                            <td class="left">{{ subnet.gateway }}</td>
                            <td class="right">{{ subnet.subnet }}</td>
                        </tr>
                    </ng-container>
                </table>
            </app-tab>
            <app-tab tabTitle="adcs" id="associated_adcs_table">
                <ng-container *ngIf="templateContent.associatedAdcs?.length else noADCs">
                    <table class="flex-table">
                        <tr>
                            <th>{{ 'label' | myLbTranslate }}</th>
                            <th>{{ 'ip_address' | myLbTranslate }}</th>
                        </tr>
                        <ng-container *ngFor="let adc of templateContent.associatedAdcs">
                            <tr>
                                <td [title]="adc.label">
                                    <a
                                        class="default"
                                        routerLink="/cm/adcs/list"
                                        [queryParams]="{sFilter: adc.id}">
                                        {{ adc.label }}
                                    </a>
                                </td>
                                <td class="right" [class.extend]="adc.ipAddress?.length > 15">
                                    <span [title]="adc.ipAddress?.length > 15 ? adc.ipAddress : ''">
                                        {{ adc.ipAddress }}
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </ng-container>
                <ng-template #noADCs>
                    <div class="empty-tab">
                        <img
                            ngSrc="assets/centralisedManagement/icons/astronaut.svg"
                            height="138"
                            width="159"
                            alt="astronaut">
                        <span class="no-adcs-message">{{ 'adc_shuttle_no_associated_adcs' | myLbTranslate }}</span>
                    </div>
                </ng-template>
            </app-tab>
        </app-tab-container>
    </div>
</ng-template>

<ng-template #actionDots let-row="row" let-displayAsButton="displayAsButton">
    <app-action-dots [displayAsButton]=displayAsButton>
        <button
            id="adcs-shuttle-update"
            data-testid="adcs-shuttle-update"
            [disabled]="!permissionsLookup['adcs-shuttle-update'] || true">
            {{ 'adc_shuttle_update' | myLbTranslate }}
        </button>
        <button
            id="adcs-shuttle-edit"
            data-testid="adcs-shuttle-edit"
            [disabled]="!permissionsLookup['adcs-shuttle-edit']"
            [routerLink]="'/cm/adcs/shuttle/edit/' + row.id">
            {{ 'adc_shuttle_edit' | myLbTranslate }}
        </button>
        <button
            id="adcs-shuttle-delete"
            data-testid="adcs-shuttle-delete"
            [disabled]="!removeButtonEnabled(row)"
            (click)="confirmRemoveShuttle(row)">
            {{ 'adc_shuttle_remove' | myLbTranslate }}
        </button>
    </app-action-dots>
</ng-template>

<app-modal-wrapper modalWidth="600" modalTitle="adc_shuttle_associated" modalId="usageModal">
    <app-modal-body>
        <h2>{{ modalShuttle }}</h2>
        <app-table id="associated-adcs-table" [data]="associatedAdcRows">
            <app-column [flexGrow]="0.75" prop="label" label="label"></app-column>
            <app-column [flexGrow]="1" prop="ipAddress" label="ip_address"></app-column>
        </app-table>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper
    modalId="confirm-adc-credentials"
    modalTitle="adc_shuttle_confirm_adc"
    [modalWidth]="410"
    [showCancelButton]="false"
    [showCloseButton]="false"
    [isOverlayClickable]="false">
    <app-modal-body>
        <p class="modal-message">{{ 'adc_shuttle_confirm_adc_message' | myLbTranslate }}</p>
        <form [formGroup]="adcForm">
            <div class="form-group">
                <label for="username">{{ 'username' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input formControlName="username" id="username" name="username" type="text">
                    <app-validation-messages [control]="adcForm.get('username')"></app-validation-messages>
                </div>
            </div>
            <div class="form-group">
                <label for="password">{{ 'password' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input formControlName="password" id="password" type="password" name="password">
                    <app-validation-messages [control]="adcForm.get('password')"></app-validation-messages>
                </div>
            </div>
        </form>
    </app-modal-body>
    <app-modal-footer>
        <button class="outline" (click)="cancelAdoption()">{{ 'cancel' | myLbTranslate }}</button>
        <app-loading-button
            buttonId="modal-confirm"
            [isDisabled]="!permissionsLookup['adcs-shuttle-adopt']"
            buttonClass="outline"
            buttonText="adc_shuttle_skip-adoption"
            [callBack]="adoptShuttle.bind(this, getShuttleValueFromForm())">
        </app-loading-button>
        <app-loading-button
            buttonId="modal-confirm"
            buttonClass="primary"
            buttonText="submit"
            [isDisabled]="!canAdoptAndSubmit()"
            [callBack]="submitShuttleAndAdc.bind(this)">
        </app-loading-button>
    </app-modal-footer>
</app-modal-wrapper>
