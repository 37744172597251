<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1>{{ 'services_restart' | myLbTranslate }}</h1>
        </div>
        <div class="content-right buttons-wrapper">
            <app-loading-button
                *ngIf="servicesRequiringRestarts"
                buttonId="restart-services-apply-services"
                buttonText="services_apply_all"
                buttonClass="primary"
                [isDisabled]="apiActive || !permissionsLookup['restart-services-apply-services']"
                [callBack]="applyAllChanges.bind(this)">
            </app-loading-button>
            <app-split-action-button
                buttonId="restart-services-restart-loadbalancer"
                buttonText="system_control_restart_lb"
                buttonClass="primary"
                [buttonDisabled]="!permissionsLookup['restart-services-restart-loadbalancer']"
                [callBack]="confirmRestartShutdown.bind(this,'restart')">
                <button
                    id="system-control-shutdown-loadbalancer"
                    [disabled]="!permissionsLookup['system-control-shutdown-loadbalancer']"
                    (click)="confirmRestartShutdown('shutdown')">
                    {{ 'system_control_shutdown_lb' | myLbTranslate }}
                </button>
            </app-split-action-button>
        </div>
    </div>

    <app-table
        id="sys-services-table"
        [data]="tableRows"
        [searchTerm]="searchTerm"
        (searchValues)="updateSearchTerm($event)">
        <app-column [flexGrow]="0.5" prop="nameFull" label="name"></app-column>
        <app-column [flexGrow]="0.5" prop="state" label="state" [cellTemplate]="serviceState">
            <ng-template let-row #serviceState class="lb-table-warning">
                <span class="status-bullet {{row.stateClass}}"></span>
                <span [class]="row.state === 'Inactive' ? 'disabled' : row.state">
                    {{ row.state | titlecase }}
                </span>
            </ng-template>
        </app-column>
        <app-column
            [flexGrow]="0.5"
            prop="actions"
            label=""
            cellAlignment="right"
            [cellTemplate]="servicesActions">
            <ng-template let-row #servicesActions>
                <app-action-dots [hidden]="row.state === 'Inactive'">
                    <button
                        *ngIf="row.reload"
                        id="restart-services-{{row.name}}-reload"
                        [disabled]="apiActive || !permissionsLookup['restart-services-row-reload']"
                        (click)="sendServiceRequest(row.name, 'reload')">
                        {{ 'reload' | myLbTranslate }}
                    </button>
                    <button
                        id="restart-services-{{row.name}}-restart"
                        [disabled]="apiActive || !permissionsLookup['restart-services-row-restart']"
                        (click)="sendServiceRequest(row.name, 'restart')">
                        {{ 'restart' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
