<div
    *ngIf="!notification.hidden"
    class="notification-item {{getLevelClassName(notification.level)}} {{notification.type}}"
    [class.hasLink]="notification.url || notification.pageReload"
    [id]="notification.id">
    <div class="notification-content">
        <div
            class="notification-text"
            [class.hasLink]="notification.url || notification.pageReload"
            (click)="navigate(notification.url, notification.urlTarget, notification.pageReload, notification.urlCloseOnClick)">
            <img [ngSrc]="getImagePathByLevel(notification.level)" alt="Notification icon" height="20" width="20"/>
            <p
                id="notification-{{notification.id}}"
                [class.is-long]="notification.message?.length > 75 && notification.type !== 'header'"
                [innerHtml]="(notification.messageTranslate ? (notification.message | myLbTranslate) : notification.message) | safeHtml">
            </p>
        </div>
        <div class="buttons-wrapper">
            <app-loading-button
                *ngIf="notification.callBack"
                buttonClass="outline {{getLevelClassName(notification.level)}}-button"
                [buttonText]="notification.callBack.text"
                [callBack]="notification.callBack.function">
            </app-loading-button>
            <button
                *ngIf="notification.level !== NotificationEnum.SUCCESS &&
                 notification.level !== NotificationEnum.INFO &&
                 notification.type !== 'header'"
                appCopyClipboard
                class="copy-icon"
                [updateButtonText]=false
                [textToCopy]="notification.message">
            </button>
            <button
                *ngIf="notification.closeButton && !hideButtons"
                class="close-button"
                (click)="removeNotification()">
                <img
                    ngSrc="assets/shared/icons/close-cross.svg"
                    alt="close notification icon"
                    height="20"
                    width="20"/>
            </button>
            <button
                *ngIf="notification.level === 3"
                class="dismiss-button"
                (click)="removeNotification()">
                <img
                    ngSrc="assets/shared/icons/chevron-right.svg"
                    alt="close notification icon"
                    height="20"
                    width="20"/>
            </button>
            <button
                *ngIf="!notification.closeButton && notification.type !== 'header' && notification.level !== 3 && !hideButtons && !notification.waiting"
                class="mute-button"
                (click)="hideNotification()">
                <i class="fas fa-bell-slash"></i>
            </button>
            <div *ngIf="notification.waiting" class="loader">
                <div class="dot-pulse"></div>
            </div>
        </div>
    </div>
</div>
