<ng-container *ngIf="isLoading; else showPageContent">
    <div class="page-loader"></div>
</ng-container>

<ng-template #showPageContent>
    <ng-container *ngIf="data; else noData">
        <div class="display-code-wrap">
            <button *ngIf="!hideCopyConfigButton" appCopyClipboard [textToCopy]="data">
                {{ 'copy_output' | myLbTranslate }}
            </button>
            <code class="code" [innerHTML]="data"></code>
        </div>
    </ng-container>
    <ng-template #noData>
        <p>{{ errorMessage | myLbTranslate }}</p>
    </ng-template>
</ng-template>
