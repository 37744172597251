<ng-container *ngIf="!hasTier else page">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="security_insights_upgrade_title"
        messageString="security_insights_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-template #page>
    <ng-container *ngIf="isLoading else pageLoaded">
        <div class="page-loader"></div>
    </ng-container>
    <ng-template #pageLoaded>
        <div class="component-main">
            <div class="content-space-between">
                <div class="content-left">
                    <h1 id="component-title">{{ 'security_insights' | myLbTranslate }}</h1>
                </div>
            </div>

            <app-table
                id="security-insights"
                [data]="tableRows"
                [allowMultiSelect]="false"
                [searchTerm]="searchTerm"
                [showPaginator]="false"
                (keyMatches)="matchedSearchKeys($event)">
                <app-column [flexGrow]="1" prop="label" label="adc_name" [cellTemplate]="adcName">
                    <ng-template let-row #adcName>
                        <img
                            class="company-logo"
                            src="assets/centralisedManagement/icons/{{adcUtilsService.getVendorIconName(row.vendor)}}-colour.svg"
                            alt="{{adcUtilsService.getVendorTitle(row.vendor)}} Logo"
                            title="{{adcUtilsService.getVendorTitle(row.vendor)}}"
                            [class.disabled]="!permissionsLookup['security-more-details']"/>
                        <button
                            class="routerLink"
                            title="{{row.label}}"
                            [routerLink]="'/cm/security/insights/details/' + row.id"
                            [disabled]="!permissionsLookup['security-more-details']">
                            {{ row.label }}
                        </button>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="0.75" prop="securityRisk" label="security_risk" [cellTemplate]="securityRisk">
                    <ng-template let-row #securityRisk>
                        <div class="security-risk{{row.securityRisk?.low === 0 ? '-disabled' : '-low'}}">
                            {{ row.securityRisk?.low }}
                        </div>
                        <div class="security-risk{{row.securityRisk?.medium === 0 ? '-disabled' : '-medium'}}">
                            {{ row.securityRisk?.medium }}
                        </div>
                        <div class="security-risk{{row.securityRisk?.high === 0 ? '-disabled' : '-high'}}">
                            {{ row.securityRisk?.high }}
                        </div>
                        <div class="security-risk{{row.securityRisk?.critical === 0 ? '-disabled' : '-critical'}}">
                            {{ row.securityRisk?.critical }}
                        </div>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="operationalRisk"
                    label="security_operational_risk"
                    [cellTemplate]="opRisk">
                    <ng-template let-row #opRisk>
                        <div class="operational-risk-{{row.operationalRisk}}" title="{{row.operationalRisk}}">
                            {{ row.operationalRisk | titlecase }}
                        </div>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="2" prop="recommendation" label="security_our_recommendation"></app-column>
                <app-column [flexGrow]="1" prop="actions" cellAlignment="right" [cellTemplate]="actions">
                    <ng-template let-row #actions>
                        <app-action-dots>
                            <button
                                id="security-more-details"
                                class="security-more-details"
                                [disabled]="!permissionsLookup['security-more-details']"
                                [routerLink]="'/cm/security/insights/details/' + row.id">
                                {{ 'security_more_details' | myLbTranslate }}
                            </button>
                            <button
                                id="security-update-adc"
                                [disabled]="!permissionsLookup['security-update-adc'] || row?.vendor !== 5"
                                appHint
                                hintPosition="left"
                                [hintText]="row?.vendor !== 5 ? ('security_update_adc_message' | myLbTranslate) : ''"
                                (click)="systemUpdateAdc(row.id)">
                                {{ 'security_update_adc' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </div>
    </ng-template>
</ng-template>
