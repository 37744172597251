<div class="menu" [class.submenu-overlays]="secondMenuFloats">
    <div class="level-1 dark-dark" [class.open]="isOpen">
        <ng-container *ngIf="secondMenuFloats else toggleHeader">
            <div class="menu-header">
                <div class="menu-toggle">
                    <img
                        ngSrc="./assets/shared/logos/logo.svg"
                        alt="Open close menu toggle"
                        height="23"
                        width="26">
                </div>
            </div>
        </ng-container>
        <ng-template #toggleHeader>
            <div class="menu-header">
                <div class="menu-toggle" (click)="toggleMenu()">
                    <ng-container *ngIf="isOpen else closed">
                        <img
                            ngSrc="./assets/shared/icons/menu-close.svg"
                            alt="Open close menu toggle"
                            height="20"
                            width="20">
                    </ng-container>
                    <ng-template #closed>
                        <img
                            ngSrc="./assets/shared/icons/menu-open.svg"
                            alt="Open close menu toggle"
                            height="20"
                            width="20">
                    </ng-template>
                </div>
                <div *ngIf="menuConfig?.menuTitleImage" class="menu-image-wrapper">
                    <img
                        class="menu-title-image"
                        ngSrc="assets/{{menuConfig?.menuTitleImage}}.svg"
                        [alt]="menuConfig?.menuTitleImage"
                        height="25"
                        width="25"/>
                </div>
                <div class="menu-titles">
                    <span class="menu-title">{{ menuConfig?.menuTitle | myLbTranslate }}</span>
                    <span *ngIf="menuConfig?.menuSubTitle" class="sub-title">
                        {{ menuConfig?.menuSubTitle | uppercase }}
                    </span>
                </div>
            </div>
        </ng-template>
        <div class="menu-items">
            <ng-container *ngFor="let primaryItem of menuConfig.menuItems">
                <div
                    *ngIf="!primaryItem.hidden"
                    [id]="primaryItem.id"
                    class="menu-item-wrapper {{primaryItem.class}}"
                    appHint
                    hintText="{{ primaryItem.heading | myLbTranslate }}"
                    hintPosition="right"
                    [class.active]="primaryItem.active"
                    [class.disabled]="primaryItem.disabled"
                    [class.coming-soon]="primaryItem.comingSoon"
                    [hintShow]="!isOpen && !secondMenuFloats"
                    (mouseenter)="mouseEnter(primaryItem)"
                    (click)="toggleNav(primaryItem)">
                    <div *ngIf="primaryItem.iconLocation" class="menu-image-wrapper">
                        <img
                            ngSrc="assets/{{primaryItem.iconLocation}}.svg"
                            alt="{{primaryItem.iconLocation}} menu icon"
                            height="20"
                            width="20">
                    </div>
                    <span>{{ primaryItem.heading | myLbTranslate }}</span>
                    <ng-container
                        [ngTemplateOutlet]="icons"
                        [ngTemplateOutletContext]="{menuItem: primaryItem}">
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="menu-footer">
            <ng-content select="[menu-footer]"></ng-content>
        </div>
    </div>
    <ng-container *ngFor="let level2MenuItem of level2MenuItems">
        <div [hidden]="!level2MenuItem.visible" class="level-2 dark-light">
            <div class="menu-header">
                <div *ngIf="level2MenuItem?.iconLocation" class="menu-image-wrapper">
                    <img
                        class="menu-title-image"
                        ngSrc="assets/{{level2MenuItem.iconLocation}}.svg"
                        [alt]="menuConfig?.menuTitleImage"
                        height="25"
                        width="25"/>
                </div>
                <div class="menu-titles">
                    <span class="menu-title">{{ level2MenuItem?.heading | myLbTranslate }}</span>
                    <span *ngIf="menuConfig?.menuSubTitle" class="sub-title">
                        {{ level2MenuItem?.subHeading | uppercase }}
                    </span>
                </div>
            </div>
            <div class="menu-items">
                <div *ngFor="let secondaryItem of level2MenuItem.children">
                    <div
                        *ngIf="!secondaryItem.hidden"
                        [id]="secondaryItem.id"
                        class="menu-item-wrapper {{secondaryItem.class}}"
                        appHint
                        hintText="{{ secondaryItem.heading | myLbTranslate }}"
                        hintPosition="right"
                        [class.active]="secondaryItem.active && !secondaryItem.children"
                        [class.disabled]="secondaryItem.disabled"
                        [class.coming-soon]="secondaryItem.comingSoon"
                        [ngClass]="toggleChevron(secondaryItem)"
                        [hintShow]="false"
                        (click)="toggleNav(secondaryItem)">
                        <div *ngIf="secondaryItem.iconLocation" class="menu-image-wrapper">
                            <img
                                ngSrc="assets/{{secondaryItem.iconLocation}}.svg"
                                alt="{{secondaryItem.iconLocation}} menu icon"
                                height="20"
                                width="20">
                        </div>
                        <span>{{ secondaryItem.heading | myLbTranslate }}</span>
                        <ng-container
                            [ngTemplateOutlet]="icons"
                            [ngTemplateOutletContext]="{menuItem: secondaryItem}">
                        </ng-container>
                    </div>
                    <div [hidden]="!(secondaryItem.children && secondaryItem.active)" class="level-3">
                        <ng-container *ngFor="let tertiaryItem of secondaryItem.children">
                            <div
                                *ngIf="!tertiaryItem.hidden"
                                [id]="tertiaryItem.id"
                                class="menu-item-wrapper {{tertiaryItem.class}}"
                                appHint
                                hintText="{{ tertiaryItem.heading | myLbTranslate }}"
                                hintPosition="right"
                                [class.active]="tertiaryItem.active"
                                [class.disabled]="tertiaryItem.disabled"
                                [class.coming-soon]="tertiaryItem.comingSoon"
                                [hintShow]="!isOpen"
                                (click)="toggleNav(tertiaryItem)">
                                <div *ngIf="tertiaryItem.iconLocation" class="menu-image-wrapper">
                                    <img
                                        ngSrc="assets/{{tertiaryItem.iconLocation}}.svg"
                                        alt="{{tertiaryItem.iconLocation}} menu icon"
                                        height="20"
                                        width="20">
                                </div>
                                <span>{{ tertiaryItem.heading | myLbTranslate }}</span>
                                <ng-container
                                    [ngTemplateOutlet]="icons"
                                    [ngTemplateOutletContext]="{menuItem: tertiaryItem}">
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #icons let-menuItem="menuItem">
    <img
        *ngIf="menuItem.externalLink && !menuItem.comingSoon"
        class="menu-item-link"
        ngSrc="./assets/centralisedManagement/icons/link.svg"
        alt="Link icon"
        height="16"
        width="16">
    <img
        *ngIf="menuItem.subscriptionRequired && !menuItem.comingSoon"
        class="menu-item-subscription"
        ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
        alt="subscription icon"
        height="21"
        width="21">
</ng-template>
