<div class="column">
    <ng-container
        matSort
        [matSortActive]="tableService.activeSort?.active"
        matColumnDef
        [sticky]="prop === 'select'"
        [stickyEnd]="prop === 'actions'"
        (matSortChange)="emitSortToParent()">
        <th
            [hidden]="columnHidden"
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="columnStyle"
            mat-sort-header
            class="{{cellAlignment}}"
            [disabled]="prop === 'select' || prop === 'actions'">
            <ng-container *ngIf="prop === 'select' && data.length">
                <input
                    id="selectAll"
                    class="lb-checkbox"
                    type="checkbox"
                    [checked]="allRowsChecked"
                    [class.partial]="tableService.selectedRows?.length && !allRowsChecked"
                    (click)="toggleAllRows($event)">
            </ng-container>
            <ng-container *ngIf="prop === 'actions' && selectAll; else defaultLabel">
                <app-action-dots>
                    <ng-container *ngFor="let button of selectAll">
                        <button
                            (click)="button.buttonAction()"
                            [disabled]="!tableService.selectedRows?.length || isButtonDisabled(button?.buttonDisabled)">
                            {{ button.buttonText | myLbTranslate }}
                        </button>
                    </ng-container>
                </app-action-dots>
            </ng-container>
            <ng-template #defaultLabel>
                <div class="cell-content {{cellAlignment}}" [style.max-width.px]="maxWidth">
                    {{ label || getFormattedName(prop) | myLbTranslate }}
                </div>
            </ng-template>
        </th>
        <td
            [hidden]="columnHidden"
            mat-cell *matCellDef="let data"
            [class.mat-table-sticky]="stickyColumn"
            [class.sort-enabled]="!disableSort">
            <ng-container *ngIf="cellTemplate; else defaultContent">
                <div class="cell-content {{prop}} {{cellAlignment}}" [style.max-width.px]="maxWidth">
                    <ng-container *ngTemplateOutlet="cellTemplate; context: {$implicit: data}">
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #defaultContent>
                <div class="cell-content {{prop}} {{cellAlignment}}" [style.max-width.px]="maxWidth">
                    <span>{{ data[prop] }}</span>
                </div>
            </ng-template>
        </td>
    </ng-container>
</div>
