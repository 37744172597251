<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'users' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="formGroup" [class.disabled]="formGroup.disabled">
        <div class="form-group">
            <label>{{ 'username' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="snmp-username"
                    title="{{'username' | myLbTranslate }}"
                    type="text"
                    size="30"
                    formControlName="username">
                <app-validation-messages [control]="formGroup.controls.username"></app-validation-messages>
                <app-help tag="snmp_username"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <div class="content radio-button-wrapper">
                    <input
                        type="radio"
                        id="snmp-auth-md5"
                        formControlName="authAlgorithm"
                        value="MD5">
                    <label for="snmp-auth-md5">{{ 'md5' | myLbTranslate }}</label>
                    <input
                        type="radio"
                        id="snmp-auth-sha"
                        formControlName="authAlgorithm"
                        value="SHA">
                    <label for="snmp-auth-sha">{{ 'sha' | myLbTranslate }}</label>
                </div>
                <app-help tag="snmp_algorithm"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="auth-passphrase">{{ 'snmp_conf_snmp_auth_passphrase' | myLbTranslate }}</label>
            <div class="form-content">
                <app-lb-reveal-password
                    id="auth-passphrase"
                    title="{{'snmp_conf_snmp_auth_passphrase' | myLbTranslate }}"
                    formControlName="authPassphrase"
                    [formGroup]="formGroup">
                </app-lb-reveal-password>
                <app-validation-messages [control]="formGroup.controls.authPassphrase"></app-validation-messages>
                <app-help tag="snmp_passphrase"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'snmp_conf_snmp_privacy_alg' | myLbTranslate }}</label>
            <div class="form-content">
                <div class="content radio-button-wrapper">
                    <input
                        type="radio"
                        id="snmp-privacy-aes"
                        formControlName="privacyAlgorithm"
                        value="AES">
                    <label for="snmp-privacy-aes">{{ 'aes' | myLbTranslate }}</label>
                    <input
                        type="radio"
                        id="snmp-privacy-des"
                        formControlName="privacyAlgorithm"
                        value="DES">
                    <label for="snmp-privacy-des">{{ 'des' | myLbTranslate }}</label>
                </div>
                <app-help tag="snmp_privacy-algorithm"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="privacy-passphrase">{{ 'snmp_conf_snmp_privacy_passphrase' | myLbTranslate }}</label>
            <div class="form-content">
                <app-lb-reveal-password
                    id="privacy-passphrase"
                    title="{{'snmp_conf_snmp_auth_passphrase' | myLbTranslate }}"
                    formControlName="privacyPassphrase"
                    [formGroup]="formGroup">
                </app-lb-reveal-password>
                <app-validation-messages [control]="formGroup.controls.privacyPassphrase"></app-validation-messages>
                <app-help tag="snmp_privacy-passphrase"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="read">{{ 'permission' | myLbTranslate }}</label>
            <div class="form-content">
                <div class="content radio-button-wrapper">
                    <input
                        type="radio"
                        id="read"
                        formControlName="writeAccess"
                        [value]="false">
                    <label for="read">{{ 'read' | myLbTranslate }}</label>
                    <input
                        type="radio"
                        id="read-write"
                        formControlName="writeAccess"
                        [value]="true">
                    <label for="read-write">{{ 'read_write' | myLbTranslate }}</label>
                </div>
                <app-help tag="snmp_privacy-passphrase"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button class="outline" [routerLink]="pathBack">{{ 'cancel' | myLbTranslate }}</button>
    <app-loading-button
        buttonId="snmp-config-user-update"
        buttonText="submit"
        buttonClass="primary"
        [isDisabled]="formGroup.invalid || formGroup.pristine || !permissionsLookup['snmp-config-user-update']"
        [callBack]="submit.bind(this)">
    </app-loading-button>
</div>

