<button
    #buttonReference
    [type]="buttonType"
    [disabled]="isDisabled || isLoading"
    [class]="buttonClass"
    [class.embedded]="buttonEmbedded"
    class="loading-button"
    [attr.id]="buttonId"
    [attr.data-testid]="buttonId"
    (click)="clickedButton()">
    <!-- Hidden text is to maintain button size when showing loading dots -->
    <span class="hidden-text">{{ buttonText | myLbTranslate }}</span>
    <div class="button-inner {{buttonClass}}">
        <ng-container *ngIf="!isLoading; else loading">
            <ng-container *ngIf="buttonIconPosition === 'left' else right">
                <img *ngIf="buttonIconLocation" [class]="buttonIconPosition" [src]="buttonIconLocation"
                     alt="Loading button icon"/>
                {{ buttonText | myLbTranslate }}
            </ng-container>
            <ng-template #right>
                {{ buttonText | myLbTranslate }}
                <img *ngIf="buttonIconLocation" [class]="buttonIconPosition" [src]="buttonIconLocation"
                     alt="Loading button icon"/>
            </ng-template>
        </ng-container>
        <ng-template #loading>
            <div class="dot-pulse"></div>
        </ng-template>
    </div>
</button>
