<ng-container *ngIf="loaded" [formGroup]="formGroup">
    <div class="form-group">
        <label for="phone">{{ 'contact_number' | myLbTranslate }}*</label>
        <div class="form-content" [class.external]="applyExternalStyling">
            <div>
                <app-dropdown
                    dropDownId="two-factor-auth-countryCode"
                    [search]="true"
                    [formGroup]="formGroup"
                    formControlName="countryCode"
                    [content]="dropdown.countryCodes"
                    [selectedTitle]="formGroup.get('countryCode')?.value"
                    [title]="selectedCountryCode"
                    [dropdownWidth]="dropdownWidth()"
                    [applyExternalStyling]="applyExternalStyling"
                    [contentWidth]="310">
                </app-dropdown>
            </div>
            <input
                id="phone"
                data-testid="phone"
                [formControlName]="numberFormName"
                [title]="(title ? title : 'contact_number') | myLbTranslate"
                autocomplete="new-password"
                appNumbersOnly
                type="text">
            <app-validation-messages [control]="formGroup.get(numberFormName)"></app-validation-messages>
        </div>
        <small [class.external]="applyExternalStyling">
            {{ 'format' | myLbTranslate }}: {{ '1234567890' }}
        </small>
    </div>
</ng-container>

