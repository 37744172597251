<div class="widget"
     [class.default]="state === WidgetStateEnum.OK"
     [class.warning]="state === WidgetStateEnum.WARNING"
     [class.critical]="state === WidgetStateEnum.CRITICAL">
    <div class="widget-header" id="widget-header">
        <div class="content-space-between">
            <h3><span *ngIf="sslEnabled" class="padlock"></span>{{ title }}</h3>
            <div *ngIf="loading" class="loader"></div>
        </div>
    </div>
    <div class="widget-content {{title}}" id="widget-content">
        <ng-container *ngIf="websocketClosed && widgetIsMetric else open">
            <div class="no-permissions">
                <p>{{ 'widget_no_data_error' | myLbTranslate }}</p>
            </div>
        </ng-container>
        <ng-template #open>
            <ng-container
                *ngIf="contentEnabled; else inactive">
                <ng-content></ng-content>
            </ng-container>
            <ng-template #inactive>
                <div class="no-permissions">
                    <p>{{ 'widget_no_access' | myLbTranslate }}</p>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>
