<app-modal-wrapper
    [showCloseButton]="showCloseButton"
    [isOverlayClickable]="false"
    [isOverlayBlurred]="true"
    [isDraggable]="false"
    modalWidth="500"
    modalTitle="{{title}}"
    modalId="countDownModal">
    <app-modal-body>
        <div class="countdown-wait-wrapper">
            <span>{{ description | myLbTranslate }}</span>
            <ng-container *ngIf="waitOption === 'countdown'; else loadingSpinner">
                <div class="counter">{{ countDownText }}</div>
            </ng-container>
            <ng-template #loadingSpinner>
                <div class="loader"></div>
            </ng-template>
        </div>
    </app-modal-body>
</app-modal-wrapper>
