<div class="component-main ">
    <div class="content-space-between">
        <div class="content-left">
            <ng-container *ngIf="isEndurance">
                <img
                    ngSrc="/assets/centralisedManagement/icons/users.svg"
                    alt="users icon"
                    height="25"
                    width="26">
            </ng-container>
            <h1 id="component-title">{{ 'users' | myLbTranslate }}</h1>
        </div>
        <div class="content-right">
            <ng-container *ngIf="activeTabId === 'users-list'">
                <button
                    id="rbac-add-user"
                    class='primary'
                    [disabled]="!permissionsLookup['rbac-add-user']"
                    (click)="addEditUser.next(false)">
                    {{ 'rbac_users_add_user' | myLbTranslate }}
                </button>
            </ng-container>
            <ng-container *ngIf="activeTabId === 'users-roles'">
                <button
                    id="rbac-add-role"
                    class='primary'
                    [disabled]="!permissionsLookup['rbac-add-role']"
                    (click)="addEditRole.next(false)">
                    {{ 'rbac_roles_add_role' | myLbTranslate }}
                </button>
            </ng-container>
            <ng-container *ngIf="activeTabId === 'users-groups'">
                <button
                    id="rbac-add-group"
                    class='primary'
                    [disabled]="!permissionsLookup['rbac-add-group']"
                    (click)="addEditGroup.next(false)">
                    {{ 'rbac_groups_add_group' | myLbTranslate }}
                </button>
            </ng-container>
        </div>
    </div>
    <app-tab-container (eventEmitter)="setActiveTab($event)">
        <app-tab id="users-list" tabTitle="users">
            <app-table
                id="rbac-users-list"
                [data]="userTableRows"
                [showPaginator]="false"
                [allowMultiSelect]="false">
                <app-column [flexGrow]="1" prop="name" label="name"></app-column>
                <app-column [flexGrow]="1" prop="username" label="email"></app-column>
                <app-column [flexGrow]="0.5" prop="status" label="status" [cellTemplate]="status">
                    <ng-template let-row #status>
                        <div class="status-dot {{row.status}}"></div>
                        {{ row.status | titlecase }}
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="roles" label="roles"></app-column>
                <app-column [flexGrow]="1" prop="groups" label="groups"></app-column>
                <app-column
                    [columnHidden]="isEndurance"
                    [flexGrow]="0.5"
                    prop="lastLoggedIn"
                    label="users_list_last_login_in">
                </app-column>
                <app-column
                    [columnHidden]="isEndurance"
                    [flexGrow]="0.2"
                    prop="otpEnabled"
                    label="users_list_last_otp_enabled"
                    cellAlignment="center"
                    [cellTemplate]="otpEnabled">
                    <ng-template let-row #otpEnabled>
                        <img
                            *ngIf="row.otpEnabled"
                            ngSrc="/assets/shared/icons/checkbox-checked.svg"
                            height="21"
                            width="20"
                            alt="checkbox"/>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5" prop="actions" label="" cellAlignment="right" [cellTemplate]="actionsUser">
                    <ng-template let-row #actionsUser>
                        <app-action-dots id="users">
                            <button
                                id="rbac-user-edit"
                                [disabled]="disabledUserEditButton(row)"
                                appHint
                                hintPosition="left"
                                [hintText]="hintText ? ('user_hint_edit' | myLbTranslate) : ''"
                                (click)="addEditUser.next(row.id)">
                                {{ (isEndurance ? 'edit' : 'rbac_users_update_permissions') | myLbTranslate }}
                            </button>
                            <button
                                *ngIf="row.name !== 'System Admin'"
                                id="rbac-user-delete"
                                [disabled]="disabledUserDeleteButton(row)"
                                appHint
                                hintPosition="left"
                                [hintText]="hintText ? ('user_hint_delete' | myLbTranslate) : row.id?.toString() === userId.toString() ? ('user_hint_delete_yourself' | myLbTranslate) :''"
                                (click)="confirmDelete('user', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
        <app-tab id="users-roles" tabTitle="roles">
            <app-table
                id="roles-list"
                [data]="rolesTableRows"
                [showPaginator]="false"
                [allowMultiSelect]="false">
                <app-column [flexGrow]="0.5" prop="name" label="name"></app-column>
                <app-column [flexGrow]="0.5" prop="users" label="users" [cellTemplate]="userAvatars">
                    <ng-template let-row #userAvatars>
                        <app-collaboration [setAvatarsByUserList]="row.users"></app-collaboration>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="groups" label="groups"></app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="actionsRoles">
                    <ng-template let-row #actionsRoles>
                        <app-action-dots id="roles">
                            <button
                                *ngIf="isNotFeatureRole(row)"
                                id="rbac-role-edit"
                                [disabled]="!row.canEditRole"
                                appHint
                                hintPosition="left"
                                [hintText]="row.userHasRole ? ('rbac_hint_role_association_update' | myLbTranslate) : ''"
                                (click)="addEditRole.next({id:row.id})">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="rbac-role-duplicate"
                                [disabled]="!permissionsLookup['rbac-role-duplicate']"
                                (click)="addEditRole.next({id:row.id, duplicate:true})">
                                {{ 'duplicate' | myLbTranslate }}
                            </button>
                            <button
                                *ngIf="isNotFeatureRole(row)"
                                id="rbac-role-delete"
                                [disabled]="!row.canDeleteRole"
                                appHint
                                hintPosition="left"
                                [hintText]="row.userHasRole ? ('rbac_hint_role_association_delete' | myLbTranslate) : ''"
                                (click)="confirmDelete('role', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
        <app-tab id="users-groups" tabTitle="groups">
            <app-table
                id="group-list"
                [data]="groupsTableRows"
                [showPaginator]="false"
                [allowMultiSelect]="false">
                <app-column [flexGrow]="0.5" prop="name" label="group"></app-column>
                <app-column [flexGrow]="0.5" prop="users" label="users" [cellTemplate]="userAvatars">
                    <ng-template let-row #userAvatars>
                        <app-collaboration [setAvatarsByUserList]="row.users"></app-collaboration>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="roles" label="roles"></app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="actionsGroups">
                    <ng-template let-row #actionsGroups>
                        <app-action-dots id="groups">
                            <button
                                id="rbac-group-edit"
                                [disabled]="!row.canEditGroup"
                                appHint
                                hintPosition="left"
                                [hintText]="row.userHasGroup ? ('rbac_hint_group_association_update' | myLbTranslate) : ''"
                                (click)="addEditGroup.next(row.id)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="rbac-group-delete"
                                [disabled]="!row.canDeleteGroup"
                                appHint
                                hintPosition="left"
                                [hintText]="row.userHasGroup ? ('rbac_hint_group_association_delete' | myLbTranslate) : ''"
                                (click)="confirmDelete('group', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
    </app-tab-container>
</div>
