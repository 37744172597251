<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalId="logoutCountdown"
    modalTitle="logout_warning_title"
    modalHeight="230"
    modalWidth="400"
    footerButtonAlignment="content-center"
    [showCloseButton]="false">
    <app-modal-body>
        <div class="logout-message-container">
            <span>{{ 'force_logout_message' | myLbTranslate }}</span>
            <h3>{{ 'logout_warning_message' | myLbTranslate }} {{ countdownTimer }}</h3>
        </div>
    </app-modal-body>
    <app-modal-footer>
        <button class="primary" (click)="logOutNow()">{{ 'accounts_settings_login_out_now' | myLbTranslate }}</button>
    </app-modal-footer>
</app-modal-wrapper>
