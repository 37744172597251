<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1 id="component-title">{{ 'certificate_details' | myLbTranslate }}</h1>
        </div>
    </div>
    <div class="content-left">
        <div class="widget">
            <div class="wrapper divider" (click)="clearSearch()">
                <div class="count">{{ widgetData.total }}</div>
                <span>{{ 'certificate_counter_label_total' |myLbTranslate }}</span>
            </div>
            <div class="wrapper divider expiring" (click)="filterDataViaWidget('expiring')">
                <div class="count warning">{{ widgetData.expiring }}</div>
                <span>{{ 'certificate_counter_label_expiring' |myLbTranslate }}</span>
            </div>
            <div class="wrapper" (click)="filterDataViaWidget('expired')">
                <div class="count error">{{ widgetData.expired }}</div>
                <span>{{ 'certificate_counter_label_expired' |myLbTranslate }}</span>
            </div>
        </div>
    </div>

    <app-table
        id="security-details"
        [data]="tableRows"
        [searchTerm]="searchTerm"
        [showPaginator]="false"
        [allowMultiSelect]="false"
        (clearSearchValues)="updateSearch($event)">
        <app-column [flexGrow]="0.5" prop="commonName" label="certificate_common_name"></app-column>
        <app-column [flexGrow]="0.4" prop="issuer" label="certificate_issuer"></app-column>
        <app-column [flexGrow]="0.5" prop="expiryDate" label="billing_expiry"></app-column>
        <app-column [flexGrow]="0.2" prop="warning" label="" [disableSort]=true [cellTemplate]="status">
            <ng-template let-row #status>
                <img
                    ngSrc="../../../../../../../assets/shared/icons/certificate.svg"
                    class="{{ row.warning }}"
                    alt="certificate warning"
                    height="21"
                    width="15"/>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.2" prop="actions" label="" [disableSort]=true [cellTemplate]="actions">
            <ng-template let-row #actions>
                <app-action-dots>
                    <button
                        id="certificate-details-edit"
                        data-testid="certificate-details-edit"
                        class="coming-soon"
                        [disabled]="true">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        id="certificate-details-delete"
                        data-testid="certificate-details-delete"
                        class="coming-soon"
                        [disabled]="true">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
