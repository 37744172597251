<div class="component-main">
    <div class="content-left">
        <h1 id="component-title">{{ 'no_access' | myLbTranslate }}</h1>
    </div>
    <div class="content-left">
        <h2>{{ 'no_access_message' | myLbTranslate }}</h2>
    </div>

    <div class="content-left">
        <img ngSrc="assets/shared/icons/notification-outline-exclamation-circle.svg" height="20" width="20">
        <h5>{{ 'no_access_requirement' | myLbTranslate }}</h5>
    </div>

    <p>{{ 'no_access_instructions' | myLbTranslate }}</p>

    <div class="token-holder">
        <p>User: {{ pageDetails.user }}</p>
        <p *ngIf="pageDetails.path">{{ pageDetails.path }}</p>

        <button
            appCopyClipboard
            class="copy-icon"
            [updateButtonText]=false
            [textToCopy]="pageDetails.fullText"
            (click)="copyCode()">
            Copy
        </button>
    </div>

    <img
        ngSrc="assets/shared/icons/no-permission.svg"
        class="page-image"
        height="302"
        width="366"
        priority
        alt="No permissions folder image"/>
</div>
