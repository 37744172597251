<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'billing' | myLbTranslate }}</h1>
        <button class="primary" [disabled]="true">
            {{ 'billing_contact' |myLbTranslate }}
        </button>
    </div>

    <app-tab-container minHeight="500">
        <app-tab tabTitle="billing_items" id="items">
            <app-table id="billing-items" [data]="billingRows">
                <app-column [flexGrow]="0.3" prop="item" label="billing_item"></app-column>
                <app-column [flexGrow]="0.5" prop="description" label="description"></app-column>
                <app-column [flexGrow]="0.5" prop="cost" label="billing_monthly_cost"></app-column>
                <app-column
                    [flexGrow]="1"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="actions">
                    <ng-template let-row #actions>
                        <app-action-dots>
                            <button
                                name="delete"
                                [disabled]="true"
                                (click)="cancelPlan()">
                                {{ 'billing_cancel_plan' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
        <app-tab tabTitle="billing_payment_methods" id="methods">
            <app-table id="billing-payment-methods" [data]="methodRows">
                <app-column [flexGrow]="2" prop="method" label="billing_payment_method">
                    <ng-template let-row="row" appLbTableColumnCell>
                        {{ row.name }} {{ row.cardDetails }}
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="name" label="billing_card_name"></app-column>
                <app-column [flexGrow]="1" prop="expiry" label="billing_expiry"></app-column>
                <app-column [flexGrow]="1" prop="default" label="default" [cellTemplate]="default">
                    <ng-template let-row #default>
                        <img
                            *ngIf="row.default"
                            ngSrc="assets/shared/icons/bullet-tick.svg"
                            alt="tick"
                            height="9"
                            width="13"/>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="1"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="actions">
                    <ng-template let-row #actions>
                        <app-action-dots>
                            <button
                                name="make-default"
                                [disabled]="row.default || true"
                                (click)="makeCardDefault(row)">
                                {{ 'billing_make_default' | myLbTranslate }}
                            </button>
                            <button
                                name="edit-payment"
                                [disabled]="true"
                                [routerLink]="'/cm/organisation/billing/edit-card/' + row.id">
                                {{ 'billing_edit_payment_method' | myLbTranslate }}
                            </button>
                            <button
                                name="remove-payment-method"
                                [disabled]="true"
                                (click)="removePaymentMethod(row)">
                                {{ 'billing_remove_payment' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
            <!-- [routerLink]="'/cm/organisation/billing/payment-method'" -->
            <a class="primary" [class.disabled]="true">
                {{ 'billing_add_payment_method' | myLbTranslate }}
            </a>
        </app-tab>
        <app-tab tabTitle="billing_invoice_history" id="history">
            <app-table id="invoice-history" [data]="historyRows">
                <app-column [flexGrow]="0.3" prop="invoice" label="billing_invoice"></app-column>
                <app-column [flexGrow]="0.5" prop="date" label="billing_date"></app-column>
                <app-column [flexGrow]="0.5" prop="amount" label="billing_amount"></app-column>
                <app-column [flexGrow]="0.5" prop="notes" label="notes"></app-column>
                <app-column [flexGrow]="0.5" prop="downloads" label="downloads">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <ng-container *ngIf="row.date">
                            <img
                                ngSrc="assets/centralisedManagement/icons/download-basic.svg"
                                alt="download"
                                height="14" width="14"
                                (click)="downloadInvoice(row)"/>
                        </ng-container>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="1"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="actions">
                    <ng-template let-row #actions>
                        <app-action-dots>
                            <button
                                name="download"
                                [disabled]="true"
                                (click)="downloadInvoice(row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
    </app-tab-container>
</div>
