<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'accounts_settings' | myLbTranslate | titlecase }}</h1>
    </div>
    <form [formGroup]="accountForm">
        <div *ngIf="activeStep === 1" formGroupName="step1">
            <div class="avatar" style="background: {{avatar.color}};">
                <span>{{avatar.initials}}</span>
            </div>
            <div class="form-group">
                <label for="first-name">{{ 'name_first' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        id="first-name"
                        data-testid="first-name"
                        formControlName="firstName"
                        title="{{ 'name_first' | myLbTranslate }}"
                        autocomplete="new-password"
                        type="text">
                    <app-validation-messages [control]="accountForm.get('step1.firstName')"></app-validation-messages>
                </div>
            </div>
            <div class="form-group">
                <label for="last-name">{{ 'name_last' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        id="last-name"
                        data-testid="last-name"
                        formControlName="lastName"
                        title="{{ 'name_last' | myLbTranslate }}"
                        autocomplete="new-password"
                        type="text">
                    <app-validation-messages [control]="accountForm.get('step1.lastName')"></app-validation-messages>
                </div>
            </div>
            <app-phone-number
                [formGroup]="getFormGroup('step1')"
                [title]="phoneNumberDisabledMessage">
            </app-phone-number>
            <div class="form-group">
                <label for="email">{{ 'email' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        id="email"
                        data-testid="email"
                        formControlName="email"
                        title="{{ 'email' | myLbTranslate }}"
                        autocomplete="new-password"
                        type="text">
                    <app-validation-messages [control]="accountForm.get('step1.email')"></app-validation-messages>
                </div>
            </div>
            <div class="form-group">
                <label for="password">{{ 'password' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        formControlName="password"
                        id="password"
                        data-testid="password"
                        title="{{ 'password' | myLbTranslate }}"
                        type="password"
                        autocomplete="new-password">
                    <app-validation-messages [control]="accountForm.get('step1.password')"></app-validation-messages>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">{{ 'password_confirm' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        formControlName="confirmPassword"
                        id="confirmPassword"
                        data-testid="confirmPassword"
                        title="{{ 'password' | myLbTranslate }}"
                        type="password"
                        autocomplete="new-password">
                    <app-validation-messages [control]="accountForm.get('step1.confirmPassword')">
                    </app-validation-messages>
                </div>
                <small class="note">{{ 'accounts_password_message' | myLbTranslate }}</small>
            </div>
        </div>
        <div *ngIf="activeStep === 2" formGroupName="step2">
            <h3>{{ 'accounts_download_key' | myLbTranslate }}</h3>
            <app-private-key
                [keyName]="privateKeyName"
                [userPassword]="accountForm.get('step1.password').value"
                (keyDecrypted)="downloadKeyAndValidate($event)">
            </app-private-key>
        </div>
    </form>
</div>
<div class="component-footer">
    <ng-container *ngIf="activeStep === 1">
        <app-loading-button
            buttonId="accounts-settings-update"
            buttonClass="primary"
            [buttonText]="passwordTouched ? 'next' : 'update_settings'"
            [isDisabled]="!permissionsLookup['update-settings'] || (accountForm.get('step1').pristine || accountForm.get('step1').invalid)"
            [callBack]="passwordTouched ? nextStep.bind(this) : updateAccount.bind(this)">
        </app-loading-button>
    </ng-container>
    <ng-container *ngIf="activeStep === 2">
        <app-loading-button
            buttonId="update-settings"
            buttonClass="primary"
            buttonText="update_settings"
            [isDisabled]="!permissionsLookup['update-settings'] || accountForm.get('step2').invalid"
            [callBack]="updateAccount.bind(this)"> <!-- this isn't an observable -->
        </app-loading-button>
    </ng-container>
</div>

<ng-container #modalContainer></ng-container>
