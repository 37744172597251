<div [hidden]="addEditMode" [class.component-main]="!componentEmbedded">
    <div class="content-space-between" [class.embedded]="componentEmbedded">
        <div class="content-left">
            <ng-container *ngIf="!componentEmbedded; else embedded">
                <h1>{{ 'floating_ip' | myLbTranslate }}</h1>
            </ng-container>
            <ng-template #embedded>
                <h2 class="embedded">{{ 'floating_ip' | myLbTranslate }}</h2>
            </ng-template>
        </div>
        <div class="content-right">
            <app-split-action-button
                buttonId="floating_ip_split_add"
                buttonText="floating_ip_add"
                buttonClass="primary"
                [buttonDisabled]="!permissionsLookup['floating-ip-add']"
                [callBack]="addEditIP.bind(this)">
                <button
                    id="floating-ip-all-enabled"
                    [disabled]="!permissionsLookup['floating-ip-enable']"
                    (click)="confirmEnableDisableAllFips('enable')">
                    {{ 'floating_ip_all_enable' | myLbTranslate }}
                </button>
                <button
                    id="floating-ip-all-disabled"
                    [disabled]="!permissionsLookup['floating-ip-disable']"
                    (click)="confirmEnableDisableAllFips('disable')">
                    {{ 'floating_ip_all_disable' | myLbTranslate }}
                </button>
            </app-split-action-button>
        </div>
    </div>
    <app-table
        id="floating-ips"
        [showPaginator]="true"
        [data]="tableRows"
        [stickyHeader]="true"
        [componentEmbedded]="componentEmbedded"
        [allowMultiSelect]="!componentEmbedded"
        [selectedRows]="selectedRowObject"
        (selectedRowsOut)="onSelect($event)">
        <app-column [flexGrow]="1.5" prop="ip" label="ip" [cellTemplate]="ipTemplate">
            <ng-template let-data #ipTemplate>
                {{ data.ip }}
                <ng-container *ngIf="checkFipIdValidation(data.id)">
                    <app-validation-messages [control]="ipForm.controls['fips']"></app-validation-messages>
                </ng-container>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.75" prop="ipv4" label="network" [cellTemplate]="networkTemplate">
            <ng-template let-data #networkTemplate>
                <div *ngIf="data.ipv4">
                    {{ data.ipv4 }}
                </div>
                <div *ngIf="data.ipv6">
                    {{ data.ipv6 }}
                </div>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1" prop="groupName" label="group"></app-column>
        <app-column [flexGrow]="1" prop="usage" label="usage" [cellTemplate]="usageTemplate">
            <ng-template let-data #usageTemplate>
                <span hidden>{{ data.usageText }}</span>
                <app-usage [usage]="data.usage" modalTitle="floating_ip_usage"></app-usage>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.75" prop="fipStatus" label="state" [cellTemplate]="stateTemplate">
            <ng-template let-data #stateTemplate>
                <span class="status-bullet {{ data.fipStatus }}"></span>
                <span [class.disabled]="!data.enabled">
                    {{ data.fipStatus | titlecase }}
                </span>
            </ng-template>
        </app-column>
        <app-column
            prop="actions"
            label=""
            [cellTemplate]="actionsTemplate"
            [selectAll]="!componentEmbedded ? selectAllObject : []">
            <ng-template let-data #actionsTemplate>
                <div>
                    <app-action-dots>
                        <button
                            id="floating-ip-enable"
                            [disabled]="!permissionsLookup['floating-ip-enable'] || data.enabled"
                            (click)="confirmEnableDisableFip(data, 'enable')">
                            {{ 'floating_ip_enable' | myLbTranslate }}
                        </button>
                        <button
                            id="floating-ip-disable"
                            [disabled]="!permissionsLookup['floating-ip-disable'] ||  !data.enabled"
                            (click)="confirmEnableDisableFip(data, 'disable')">
                            {{ 'floating_ip_disable' | myLbTranslate }}
                        </button>
                        <button
                            id="floating-ip-edit"
                            [disabled]="!permissionsLookup['floating-ip-edit']"
                            (click)="addEditIP(data)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                        <button
                            id="floating-ip-delete"
                            [disabled]="!permissionsLookup['floating-ip-delete'] || data.usage.length"
                            [attr.title]="data.usage.length ? ('floating_ip_in_use' | myLbTranslate) : null"
                            (click)="confirmDeleteFip(data)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </div>
            </ng-template>
        </app-column>
    </app-table>
</div>

<ng-container #addEditContainer></ng-container>
