import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Optional,
    Output
} from '@angular/core';
import { DownloadService, TableService, UtilsService } from '../../../services';
import { DropdownModel } from '../../../models';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import {
    _MatPaginatorBase,
    MAT_PAGINATOR_DEFAULT_OPTIONS,
    MatPaginatorDefaultOptions,
    MatPaginatorIntl
} from '@angular/material/paginator';

@Component({
    selector: 'app-custom-paginator',
    templateUrl: './app-custom-paginator.component.html',
    styleUrls: ['./app-custom-paginator.component.scss']
})
export class AppCustomPaginatorComponent extends _MatPaginatorBase<MatPaginatorDefaultOptions> implements AfterViewInit, OnDestroy {
    /**
     * Store the selected rows.
     */
    @Input() selectedRows: any;

    /**
     * Store the datasource.
     * @param datasource
     */
    @Input() set datasource(datasource: MatTableDataSource<any>) {
        this._datasource = datasource;
        this.dataLength = datasource?.filteredData.length;
        this.updateTotalPages();
        this.setIndicesForCurrentPage();
    }

    /**
     * Update page numbers based on filtered data.
     * @param filteredData
     */
    @Input() set filteredData(filteredData: any[]) {
        this._filteredData = filteredData;
        this.dataLength = filteredData.length;
        this.updateTotalPages();
        this.setIndicesForCurrentPage();
    }

    /**
     * Set the id for the paginator.
     * @param id
     */
    @Input() set id(id: string) {
        this._id = id;
        this['id'] = id;
    }

    /**
     * Output the paginator data.
     */
    @Output() paginatorData: EventEmitter<any> = new EventEmitter();

    /**
     * Store the dropdown for the page size.
     */
    public pageSizeDropdown: any = [];

    /**
     * Store the page numbers.
     */
    public visiblePageNumbers: number[] = [];

    /**
     * Show the previous page group ellipsis.
     */
    public showPreviousEllipsis: boolean = false;

    /**
     * Show the previous page group ellipsis.
     */
    public showNextEllipsis: boolean = false;

    /**
     * Index of the first item on the current page.
     */
    public pageStart: number;

    /**
     * Index of the last item on the current page.
     */
    public pageEnd: number;

    /**
     * Component destroyed subject for our subscribers.
     * @protected
     */
    protected destroyed$: Subject<boolean> = new Subject();

    /**
     * Store the datasource.
     * @private
     */
    private _datasource: any;

    /**
     * Store the total page.
     * @private
     */
    private _totalPages: number;

    /**
     * Number of table items stored.
     * @private
     */
    private _dataLength: number;

    /**
     * Id for the paginator taken from the table name.
     * @private
     */
    private _id: string;

    /**
     * Store the filtered data.
     * @private
     */
    private _filteredData: any[];

    /**
     * Store number of page visible to the user.
     * @private
     */
    private visiblePageRange: number = 5;

    /**
     * Store direction in which the user is navigating through the page.
     * @private
     */
    private direction: 'forward' | 'back' = 'forward';

    /**
     * First load flag.
     * @private
     */
    private firstLoad: boolean = true;

    constructor(
        intl: MatPaginatorIntl,
        changeDetectorRef: ChangeDetectorRef,
        private downloadService: DownloadService,
        private tableService: TableService,
        private utilsService: UtilsService,
        @Optional() @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) defaults?: MatPaginatorDefaultOptions,
    ) {
        super(intl, changeDetectorRef, defaults);
        this.setupDropdownContent();
        this.subscribeToPageEvents();
        this.pageSize = 5;
        this.totalPages = Math.ceil(this.dataLength / this.pageSize);
    }

    /**
     * Set the total page count.
     * @param totalPages
     */
    set totalPages(totalPages: number) {
        this._totalPages = totalPages;
        // TODO: Disabled until paginator ticket has been started.
        // this.drawPageNumbers();
        // this.updateVisibleRange();
        if (!this.visiblePageNumbers.includes(this.pageIndex + 1) && this.shouldUpdateVisiblePages()) {
            this.updateVisiblePages();
        }
    }

    /**
     * Set the total number of entries.
     * @param length
     */
    set dataLength(length: number) {
        this._dataLength = length;
    }

    /**
     * Get the datasource.
     */
    get datasource() {
        return this._datasource;
    }

    /**
     * Get the total page count.
     */
    get totalPages() {
        return this._totalPages;
    }

    /**
     * Get the number of data entries.
     */
    get dataLength() {
        return this._dataLength;
    }

    /**
     * Get filtered data.
     */
    get filteredData() {
        return this._filteredData;
    }

    /**
     * Get the id of the paginator.
     */
    get id() {
        return this._id;
    }

    public ngAfterViewInit(): void {
        this.tableService.paginatorInitialised.emit(this);
        this.setIndicesForCurrentPage();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    /**
     * Download CSV
     */
    public downloadCSV(): void {
        const csv = this.utilsService.convertArrayOfObjectToCSV(this.datasource.filteredData);
        const timestamp = Math.floor(Date.now() / 1000);
        this.downloadService.generateDownload(csv, `${this.id}-${timestamp}.csv`, { type: 'type/plain' });
    }

    /**
     * Update the table with the latest page size.
     * @param $event
     */
    public updatePage($event: any): void {
        this._changePageSize($event);
    }

    /**
     * Shift forward.
     */
    public shiftForward(): void {
        const nextPage: number = this.visiblePageNumbers[this.visiblePageNumbers.length - 1] + 1;
        this.goToPage(nextPage);
    }

    /**
     * Shift back.
     */
    public shiftBack(): void {
        this.goToPage(this.visiblePageNumbers[0] - 1);
    }

    /**
     * Go to page.
     * @param number
     */
    public goToPage(number: number): void {
        const previousPageIndex = this.pageIndex;
        this.pageIndex = number - 1;
        this.page.emit({
            pageIndex: number - 1,
            pageSize: this.pageSize,
            length: this.datasource.filteredData.length,
            previousPageIndex: previousPageIndex
        });
    }

    /**
     * Update visible page.
     * @private
     */
    private updateVisiblePages(): void {
        this.visiblePageNumbers = [];
        const currentPage = this.pageIndex + 1;
        let length: number, start: number, end: number;

        if (currentPage === 1) {
            start = currentPage + 1;
        } else if (currentPage === this.totalPages) {
            return;
        } else {
            start = currentPage;
        }

        if (this.firstLoad) {
            length = Math.min(this.totalPages, this.visiblePageRange);
            end = start >= length ? this.totalPages : length;
            this.setVisiblePagesAsc(start, end);
            this.firstLoad = false;
        } else {
            if (this.direction === 'forward') {
                length = Math.min(this.totalPages, this.visiblePageRange);
                end = start >= length ? this.totalPages : length;
                this.setVisiblePagesAsc(start, end);
            }

            if (this.direction === 'back') {
                end = start < this.visiblePageRange ? 1 : this.visiblePageRange;
                this.setVisiblePagesDesc(start, end);
            }
        }

        this.toggleNextEllipsis();
    }

    /**
     * Re-draw the visible page numbers when navigating back.
     * @param start
     * @param end
     * @private
     */
    private setVisiblePagesDesc(start: number, end: number): void {
        for (let i = start; i > end; i--) {
            if (!this.visiblePageNumbers.includes(i)) {
                this.visiblePageNumbers.unshift(i);
            }
        }
    }

    /**
     * Re-draw the visible page numbers when navigating forward.
     * @param start
     * @param end
     * @private
     */
    private setVisiblePagesAsc(start: number, end: number): void {
        for (let i = start; i < end; i++) {
            if (!this.visiblePageNumbers.includes(i)) {
                this.visiblePageNumbers.push(i);
            }
        }
    }

    /**
     * Toggle the forward navigation ellipsis.
     * @private
     */
    private toggleNextEllipsis(): void {
        const lastNumberInRange = this.visiblePageNumbers[this.visiblePageNumbers.length - 1];
        if (this.totalPages >= 6 && (this.totalPages - lastNumberInRange) !== 1) {
            this.showNextEllipsis = true;
        }

        if (this.totalPages >= 6) {
            this.showNextEllipsis = (this.totalPages - lastNumberInRange) !== 1;
            this.showPreviousEllipsis = !(this.visiblePageNumbers[0] <= this.visiblePageRange
                && this.visiblePageNumbers[this.visiblePageNumbers.length - 1] <= this.visiblePageRange);
        }

        if (this.totalPages <= 6) {
            this.showNextEllipsis = false;
            this.showPreviousEllipsis = false;
        }
    }

    /**
     * Determine if the page numbers need to update.
     * @private
     */
    private shouldUpdateVisiblePages(): boolean {
        const currentPage = this.pageIndex + 1;
        const isLastSection = this.totalPages - this.visiblePageNumbers[this.visiblePageNumbers.length - 1] === 1;
        const isFirstSection = this.visiblePageNumbers.includes(2);

        return !(currentPage === this.totalPages && isLastSection) && !(currentPage === 1 && isFirstSection);
    }

    /**
     * Setup dropdown content.
     * @private
     */
    private setupDropdownContent(): void {
        this.pageSizeDropdown = [
            <DropdownModel>{ key: 1, value: 1 },
            <DropdownModel>{ key: 2, value: 2 },
            <DropdownModel>{ key: 5, value: 5 },
            <DropdownModel>{ key: 10, value: 10 },
            <DropdownModel>{ key: 20, value: 20 },
            <DropdownModel>{ key: 100, value: 100 },
        ];
    }

    /**
     * Subscribe to the page events.
     * @private
     */
    private subscribeToPageEvents(): void {
        this.page
            .subscribe({
                next: (event: any): void => {
                    this.direction = event.previousPageIndex <= this.pageIndex ? 'forward' : 'back';
                    this.updateTotalPages();
                    this.setIndicesForCurrentPage();

                    this.paginatorData.next({
                        pageIndex: this.pageIndex,
                        pageSize: event.pageSize,
                        length: this.datasource.filteredData.length
                    });
                }
            });
    }

    /**
     * Update the page count.
     * @private
     */
    private updateTotalPages(): void {
        this.totalPages = Math.ceil(this.dataLength / this.pageSize);
    }

    /**
     * Set start and end index for current page.
     * @private
     */
    private setIndicesForCurrentPage(): void {
        if (this.datasource?.paginator) {
            const { startIndex, endIndex } = this.tableService.getIndicesForCurrentPage(this.datasource);
            this.pageStart = this.datasource.filteredData.length === 0 ? 0 : startIndex + 1;
            this.pageEnd = endIndex >= this.datasource.filteredData.length ? endIndex : endIndex + 1;
        }
    }
}
