<div [class.component-main]="!componentEmbedded" [hidden]="isEmbeddedPageOpen" [class.embedded]="componentEmbedded">
    <div class="content-space-between" [class.embedded]="componentEmbedded">
        <div class="content-left">
            <ng-container *ngIf="!componentEmbedded; else embeddedTitle">
                <h1>{{ 'ssl_certificate' | myLbTranslate }}</h1>
            </ng-container>
            <ng-template #embeddedTitle>
                <h2>{{ 'ssl_certificate' | myLbTranslate }}</h2>
            </ng-template>
        </div>
    </div>
    <app-tab-container
        id="certs-container"
        [vertical]="false"
        [tabPermissions]="permissionsLookup"
        [widthPercent]="100">
        <app-tab
            id="ssl-list-certs"
            tabTitle="certs">
            <ng-template #certsButtons>
                <button
                    id="ssl-certificates-create-cert"
                    class='primary'
                    [disabled]="!permissionsLookup['ssl-certificates-create-cert']"
                    (click)="openEmbeddedPage('/cert/add', 'addEditCert')">
                    {{ 'certificate_add_cert' | myLbTranslate }}
                </button>
                <button
                    id="ssl-certificates-upload"
                    class='primary'
                    [disabled]="!permissionsLookup['ssl-certificates-upload']"
                    (click)="openEmbeddedPage('/cert/upload', 'uploadCert')">
                    {{ 'upload_pem_pfx' | myLbTranslate }}
                </button>
            </ng-template>
            <app-table
                id="ssl-certs"
                [data]="certsRows"
                [embeddedButtons]="certsButtons"
                [stickyHeader]="true">
                <app-column [flexGrow]="1" prop="name" label="name"></app-column>
                <app-column [flexGrow]="1" prop="commonName" label="certificate_common_name"></app-column>
                <app-column [flexGrow]="0.5" prop="validTo" label="valid_to"></app-column>
                <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="certsUsage">
                    <ng-template let-row #certsUsage>
                        <app-usage [usage]="row.usage" modalTitle="ssl_certificates_usage"></app-usage>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5" prop="actions" label="" cellAlignment="right"
                    [cellTemplate]="certsActions">
                    <ng-template let-row #certsActions>
                        <app-action-dots>
                            <!-- Use hidden instead of ngIf in action dots -->
                            <button
                                id="certs-edit-cert"
                                [disabled]="!permissionsLookup['ssl-certificates-edit-cert']"
                                (click)="openEmbeddedPage('/cert/edit/' + row.id, 'addEditCert', row.id)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="certs-delete-cert"
                                *ngIf="row.usage.length === 0"
                                [disabled]="!permissionsLookup['ssl-certificates-delete-cert']"
                                (click)="confirmDelete(row, 'certificate', 'deleteCert')">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
        <app-tab
            id="ssl-list-csrs"
            tabTitle="csr">
            <ng-container *ngIf="csrsRows">
                <ng-template #csrButtons>
                    <button
                        id="'ssl-certificates-create-csr'"
                        class='primary'
                        [disabled]="!permissionsLookup['ssl-certificates-create-csr']"
                        (click)="openEmbeddedPage('/csr/new', 'addEditCSR')">
                        {{ 'certificate_create_csr' | myLbTranslate }}
                    </button>
                </ng-template>
                <!--                TODO: Need to figure out a way to align external buttons with table's search bar. -->
                <app-table
                    id="csr-table"
                    [data]="csrsRows"
                    [embeddedButtons]="csrButtons"
                    [stickyHeader]="true">
                    <app-column [flexGrow]="1" prop="name" label="name"></app-column>
                    <app-column [flexGrow]="1" prop="commonName" label="certificate_common_name"></app-column>
                    <app-column [flexGrow]="0.5" prop="organisation" label="organization"></app-column>
                    <app-column [flexGrow]="1" prop="subjectAlt" label="certificate_csrs_subject_alt"></app-column>
                    <app-column
                        [flexGrow]="0.5"
                        prop="actions"
                        label=""
                        cellAlignment="right"
                        [cellTemplate]="csrActions">
                        <ng-template let-row #csrActions>
                            <app-action-dots>
                                <!-- Use hidden instead of ngIf in action dots -->
                                <button
                                    id="ssl-certificates-apply-csr"
                                    [disabled]="!permissionsLookup['ssl-certificates-create-csr']"
                                    (click)="this.openEmbeddedPage('/cert/apply-csr/' + row.id, 'uploadCert', row.id);">
                                    {{ 'certificate_apply_csr' | myLbTranslate }}
                                </button>
                                <button
                                    id="ssl-certificates-view-csr"
                                    [disabled]="!permissionsLookup['ssl-certificates-view-csr']"
                                    (click)="viewCSR(row.id)">
                                    {{ 'certificate_view_csr' | myLbTranslate }}
                                </button>
                                <button
                                    id="ssl-certificates-edit-csr"
                                    [disabled]="!permissionsLookup['ssl-certificates-edit-csr']"
                                    (click)="openEmbeddedPage('/csr/edit/' + row.id, 'addEditCSR', row.id)">
                                    {{ 'edit' | myLbTranslate }}
                                </button>
                                <!-- TODO: This will need to change once we can distinguish between CSRs and Self-signed certs -->
                                <button
                                    *ngIf="row.selfSigned"
                                    id="ssl-certificates-renew-self-signed"
                                    [disabled]="!permissionsLookup['ssl-certificates-renew-self-signed']"
                                    (click)="renewSelfSigned(row.id)">
                                    {{ 'renew' | myLbTranslate }}
                                </button>
                                <button
                                    id="ssl-certificates-delete-csr"
                                    [disabled]="!permissionsLookup['ssl-certificates-delete-csr']"
                                    (click)="confirmDelete(row, 'CSR', 'deleteCSR')">
                                    {{ 'delete' | myLbTranslate }}
                                </button>
                            </app-action-dots>
                        </ng-template>
                    </app-column>
                </app-table>
            </ng-container>
        </app-tab>
        <app-tab
            id="ssl-list-cas"
            tabTitle="ca">
            <ng-template #caButtons>
                <button
                    id="ssl-certificates-create-ca"
                    class='primary'
                    [disabled]="!permissionsLookup['ssl-certificates-create-ca']"
                    (click)="openEmbeddedPage('/ca/new', 'addEditCA')">
                    {{ 'certificate_create_ca' | myLbTranslate }}
                </button>
            </ng-template>
            <app-table
                id="ssl-cas"
                [data]="casRows"
                [embeddedButtons]="caButtons"
                [stickyHeader]="true">
                <app-column [flexGrow]="1" prop="name" label="name"></app-column>
                <app-column [flexGrow]="1" prop="commonName" label="certificate_common_name"></app-column>
                <app-column [flexGrow]="0.5" prop="validTo" label="valid_to"></app-column>
                <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="certsUsage">
                    <ng-template let-row #certsUsage>
                        <app-usage [usage]="row.usage" modalTitle="ssl_certificates_usage"></app-usage>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5" prop="actions" label="" cellAlignment="right"
                    [cellTemplate]="caActions">
                    <ng-template let-row #caActions>
                        <app-action-dots>
                            <!-- Use hidden instead of ngIf in action dots -->
                            <button
                                id="certs-edit-ca"
                                [disabled]="!permissionsLookup['ssl-certificates-edit-ca']"
                                (click)="openEmbeddedPage('/ca/edit/' + row.id, 'addEditCA', row.id)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="certs-delete-ca"
                                *ngIf="row.usage.length === 0"
                                [disabled]="!permissionsLookup['ssl-certificates-delete-ca']"
                                (click)="confirmDelete(row, 'CA','deleteCA')">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
        <app-tab
            id="ssl-list-crls"
            tabTitle="crl">
            <ng-template #crlButtons>
                <button
                    id="ssl-certificates-create-crl"
                    class='primary'
                    [disabled]="!permissionsLookup['ssl-certificates-create-crl']"
                    (click)="openEmbeddedPage('/crl/new', 'addEditCRL')">
                    {{ 'certificate_create_crl' | myLbTranslate }}
                </button>
            </ng-template>
            <app-table
                id="ssl-crls"
                [data]="crlsRows"
                [embeddedButtons]="crlButtons"
                [stickyHeader]="true">
                <app-column [flexGrow]="1" prop="name" label="name"></app-column>
                <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="certsUsage">
                    <ng-template let-row #certsUsage>
                        <app-usage [usage]="row.usage" modalTitle="ssl_certificates_usage"></app-usage>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5" prop="actions" label="" cellAlignment="right"
                    [cellTemplate]="crlActions">
                    <ng-template let-row #crlActions>
                        <app-action-dots>
                            <!-- Use hidden instead of ngIf in action dots -->
                            <button
                                id="certs-edit-crl"
                                [disabled]="!permissionsLookup['ssl-certificates-edit-crl']"
                                (click)="openEmbeddedPage('/crl/edit/' + row.id, 'addEditCRL', row.id)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="certs-delete-crl"
                                *ngIf="row.usage.length === 0"
                                [disabled]="!permissionsLookup['ssl-certificates-delete-crl']"
                                (click)="confirmDelete(row, 'CRL', 'deleteCRL')">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
    </app-tab-container>
</div>

<div *ngIf="componentEmbedded" class="component-footer embedded">
    <button id="ssl-certs-wizard-cancel" class="outline" (click)="callBack()">
        {{ 'cancel' | myLbTranslate }}
    </button>
</div>

<ng-container #addEditContainer></ng-container>

<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalId="view-csr-modal"
    modalTitle="csr"
    modalWidth="500"
    footerButtonAlignment="content-center"
    [showCloseButton]="true">
    <app-modal-body>
        <div class="textarea-wrap">
            <label>{{ 'certificate' | myLbTranslate }}</label>
            <button appCopyClipboard [textToCopy]="csrData?.csr">
                {{ 'copy_output' | myLbTranslate }}
            </button>
            <textarea
                id="edit-csr-cert"
                title="{{ 'certificate' | myLbTranslate }}"
                rows="6"
                name="cert-content"
                class="textarea-disabled">{{ csrData?.csr }}
            </textarea>
        </div>
        <br>
        <div class="text-area-wrap">
            <label>Private Key</label>
            <button appCopyClipboard [textToCopy]="csrData?.privateKey">
                {{ 'copy_output' | myLbTranslate }}
            </button>
            <textarea
                id="edit-csr-privatekey"
                title="{{ 'certificate' | myLbTranslate }}"
                rows="6"
                name="cert-content"
                class="textarea-disabled">{{ csrData?.privateKey }}
            </textarea>
        </div>
    </app-modal-body>
</app-modal-wrapper>
