<div class="component-main">
    <div class="content-space-between">
        <h2>{{ 'static_routes' | myLbTranslate }}</h2>
        <button
            id="static-route-add-new"
            class="primary"
            [disabled]="isButtonDisabled('static-route-add-new')"
            (click)="addEditRoute()">
            {{ 'static_route_add' | myLbTranslate }}
        </button>
    </div>
    <app-table
        id="static-routes-list"
        [data]="tableRows"
        [allowMultiSelect]="true"
        [showPaginator]="false"
        [stickyHeader]="true"
        [showSearch]="true"
        [searchTerm]="searchTerm"
        [selectedRows]="selectedRowObject"
        (selectedRowsOut)="onSelect($event)">
        <app-column [flexGrow]="1.5" prop="gateway" label="gateway"></app-column>
        <app-column [flexGrow]="1" prop="type" label="type"></app-column>
        <app-column [flexGrow]="1" prop="groupName" label="group"></app-column>
        <app-column
            prop="actions"
            label=""
            cellAlignment="right"
            [selectAll]="selectAllActions"
            [cellTemplate]="actionsTemplate">
            <ng-template let-row #actionsTemplate>
                <app-action-dots>
                    <button
                        id="static-route-edit"
                        [disabled]="isButtonDisabled('static-route-edit')"
                        (click)="addEditRoute(row)">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        id="static-route-delete"
                        [disabled]="isButtonDisabled('static-route-delete') || row.type === 'default'"
                        (click)="confirmDeleteIndividualRoute(row)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
