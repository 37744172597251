<div class="component-main" *ngIf="dataAvailable">
    <div class="content">
        <h1>{{ 'gslb' | myLbTranslate }}</h1>
    </div>
    <ng-container *ngIf="poolsOfflineTotal">
        <div class="widgetContainer" id="gslb-widget-container">
            <app-widget
                title="{{ 'alerts' | myLbTranslate }}"
                id="gslb-widget">
                <div class="content-half-width-center">
                    <div class="content-vertical-middle">
                        <h1 class="critical">{{ poolsOfflineTotal }}</h1>
                        <h2>{{ 'offline' | myLbTranslate }}</h2>
                    </div>
                </div>
            </app-widget>
        </div>
    </ng-container>

    <app-tab-container id="gslb" [activeTabId]="activeTab">
        <app-tab tabTitle="gslb_global_names" id="global-names">
            <ng-template #globalNamesButtons>
                <button
                    id="gslb-add-globalname"
                    [disabled]="!permissionsLookup['gslb-add-globalname']"
                    class='primary'
                    (click)="navigateToAddEdit('/cluster-configuration/gslb/global-names')">
                    {{ 'gslb_add_global_names' | myLbTranslate }}
                </button>
            </ng-template>
            <app-table
                id="gslb-global-names"
                [data]="globalNames"
                [allowMultiSelect]="false"
                [showPaginator]="false"
                [embeddedButtons]="globalNamesButtons"
                [stickyHeader]="true">
                <app-column [flexGrow]="1" prop="domain" label="gslb_global_name">
                </app-column>
                <app-column [flexGrow]="1" prop="timeToLive" label="ttl"></app-column>
                <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="globalNamesUsage">
                    <ng-template let-row #globalNamesUsage>
                        <app-usage [usage]="row.usage" modalTitle="global_names_usage"></app-usage>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.75"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="globalNamesActions">
                    <ng-template let-row #globalNamesActions>
                        <app-action-dots>
                            <button
                                id="gslb-edit-globalname"
                                [disabled]="!permissionsLookup['gslb-edit-globalname']"
                                (click)="navigateToAddEdit('/cluster-configuration/gslb/global-names', row)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="gslb-delete-globalname"
                                [disabled]="!permissionsLookup['gslb-delete-globalname'] || row.usage?.length"
                                (click)="confirmDelete('globalName', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>

        <app-tab tabTitle="members" id="members">
            <ng-template #membersButtons>
                <button
                    id="gslb-add-member"
                    [disabled]="!permissionsLookup['gslb-add-member']"
                    class='primary'
                    (click)="navigateToAddEdit('/cluster-configuration/gslb/members')">
                    {{ 'gslb_add_member' | myLbTranslate }}
                </button>
            </ng-template>
            <app-table
                id="gslb-members"
                [data]="members"
                [allowMultiSelect]="false"
                [showPaginator]="false"
                [embeddedButtons]="membersButtons"
                [stickyHeader]="true">
                <app-column [flexGrow]="1" prop="name" label="name"></app-column>
                <app-column [flexGrow]="1" prop="ip" label="ip"></app-column>
                <app-column [flexGrow]="1" prop="weight" label="weight"></app-column>
                <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="membersUsage">
                    <ng-template let-row #membersUsage>
                        <app-usage [usage]="row.usage" modalTitle="global_names_usage"></app-usage>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="membersActions">
                    <ng-template let-row #membersActions>
                        <app-action-dots>
                            <button
                                id="gslb-edit-member"
                                [disabled]="!permissionsLookup['gslb-edit-member']"
                                (click)="navigateToAddEdit('/cluster-configuration/gslb/members', row)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="gslb-delete-member"
                                [disabled]="!permissionsLookup['gslb-delete-member'] || row.usage?.length"
                                (click)="confirmDelete('member', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>

        <app-tab tabTitle="pools" id="pools">
            <ng-template #poolsButtons>
                <button
                    id="gslb-add-pool"
                    class='primary'
                    [disabled]="!permissionsLookup['gslb-add-pool']"
                    (click)="navigateToAddEdit('/cluster-configuration/gslb/pools')">
                    {{ 'gslb_add_pool' | myLbTranslate }}
                </button>
            </ng-template>
            <app-table
                id="gslb-pools"
                [data]="poolsTableRows"
                [allowMultiSelect]="false"
                [showPaginator]="false"
                [embeddedButtons]="poolsButtons"
                [stickyHeader]="true">
                <app-column [flexGrow]="1" prop="name" label="name"></app-column>
                <app-column [flexGrow]="1" prop="monitor" label="monitor"></app-column>
                <app-column [flexGrow]="1" prop="lbMethod" label="gslb_lb_method"></app-column>
                <app-column [flexGrow]="1" prop="status" label="status" [cellTemplate]="poolsStatus">
                    <ng-template let-row #poolsStatus class="lb-table-warning">
                        <span
                            class="status-bullet {{row.status === 'offline' || row.status === 'pending' ? 'inactive': 'active' }}"></span>
                        <span [class]="row.status === 'offline' || row.status ===  'pending' ? 'disabled' : 'active'">
                            {{ row.status | titlecase }}
                        </span>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="poolsActions">
                    <ng-template let-row #poolsActions>
                        <app-action-dots>
                            <button
                                id="gslb-edit-pool"
                                [disabled]="!permissionsLookup['gslb-edit-pool']"
                                (click)="navigateToAddEdit('/cluster-configuration/gslb/pools', row)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="gslb-delete-pool"
                                [disabled]="!permissionsLookup['gslb-delete-pool']"
                                (click)="confirmDelete('pool', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>

        <app-tab tabTitle="topologies" id="topologies">
            <ng-template #topologyButtons>
                <button
                    id="gslb-add-topology"
                    class="primary"
                    [hidden]="members?.length === 0"
                    [disabled]="!permissionsLookup['gslb-add-topology']"
                    (click)="navigateToAddEdit('/cluster-configuration/gslb/topologies')">
                    {{ 'gslb_add_topology' | myLbTranslate }}
                </button>
            </ng-template>
            <ng-container *ngIf="members?.length; else noMembers">
                <app-table
                    id="gslb-topologies"
                    [data]="topologiesTableRows"
                    [allowMultiSelect]="false"
                    [showPaginator]="false"
                    [embeddedButtons]="topologyButtons"
                    [stickyHeader]="true">
                    <app-column [flexGrow]="1" prop="subnet" label="subnet"></app-column>
                    <app-column [flexGrow]="1" prop="member" label="member"></app-column>
                    <app-column
                        [flexGrow]="0.5"
                        prop="actions"
                        label=""
                        cellAlignment="right"
                        [cellTemplate]="topologyActions">
                        <ng-template let-row #topologyActions>
                            <app-action-dots>
                                <button
                                    id="gslb-edit-topology"
                                    [disabled]="!permissionsLookup['gslb-edit-topology']"
                                    (click)="navigateToAddEdit('/cluster-configuration/gslb/topologies', row)">
                                    {{ 'edit' | myLbTranslate }}
                                </button>
                                <button
                                    id="gslb-delete-topology"
                                    [disabled]="!permissionsLookup['gslb-delete-topology']"
                                    (click)="confirmDelete('topology', row)">
                                    {{ 'delete' | myLbTranslate }}
                                </button>
                            </app-action-dots>
                        </ng-template>
                    </app-column>
                </app-table>
            </ng-container>
            <ng-template #noMembers>
                <h1>{{ 'gslb_no_members' | myLbTranslate }}</h1>
                <span>{{ 'gslb_no_members_text' | myLbTranslate }}</span>
            </ng-template>
        </app-tab>
    </app-tab-container>
</div>
