<div class="component-main">
    <div class="content-space-between">
        <h2 class="embedded">{{ 'static_ip' | myLbTranslate }}</h2>
        <button
            id="static-ip-add-new"
            class="primary"
            [disabled]="!permissionsLookup['static-ip-add']"
            (click)="addEditSIP()">
            {{ 'static_ip_add' | myLbTranslate }}
        </button>
    </div>
    <app-table
        id="static-ips"
        [data]="tableRows"
        [allowMultiSelect]="true"
        [showPaginator]="false"
        [stickyHeader]="true"
        [selectedRows]="selectedRowObject"
        (selectedRowsOut)="onSelect($event)">
        <app-column [flexGrow]="1.5" prop="ip" label="ip"></app-column>
        <app-column [flexGrow]="0.75" prop="ipv4" label="network" [cellTemplate]="ipv4">
            <ng-template let-row #ipv4>
                <div *ngIf="row.ipv4">
                    {{ row.ipv4 }}
                </div>
                <div *ngIf="row.ipv6">
                    {{ row.ipv6 }}
                </div>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1" prop="groupName" label="group"></app-column>
        <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="fipUsage">
            <ng-template let-row #fipUsage>
                <app-usage [usage]="row.usage" modalTitle="floating_ip_usage"></app-usage>
            </ng-template>
        </app-column>
        <app-column
            [flexGrow]="1"
            prop="actions"
            label=""
            cellAlignment="right"
            [cellTemplate]="sipActions"
            [selectAll]="actionDotsArray">
            <ng-template let-row #sipActions>
                <div>
                    <app-action-dots>
                        <button
                            id="static-ip-edit"
                            [disabled]="!permissionsLookup['static-ip-edit'] || isBoundToUserInterface(row)"
                            (click)="addEditSIP(row)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                        <button
                            id="static-ip-delete"
                            [disabled]="!permissionsLookup['static-ip-delete'] || row.usage.length"
                            (click)="confirmDeleteIndividualIP(row)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </div>
            </ng-template>
        </app-column>
    </app-table>
</div>
