<div id="page-wrapper">
    <div id="content-wrapper">
        <div class="component-main">
            <div class="content-space-between">
                <h1>{{ 'notifications' | myLbTranslate }}</h1>
                <button (click)="clearNotifications()" class="primary">{{ 'notifications_clear' | myLbTranslate }}
                </button>
            </div>

            <app-dropdown
                dropDownId="notifications-type"
                displayAsInput="true"
                [content]="dropdownContent"
                [dropdownWidth]="400"
                [selectedKey]="9"
                (keyOut)="updateTable($event)">
            </app-dropdown>

            <app-table
                id="notifications-table"
                [data]="tableRows"
                [allowMultiSelect]="true"
                [selectedRows]="selectedRowObject">
                <app-column
                    [flexGrow]="1"
                    prop="message"
                    label="notification"
                    [cellTemplate]="notificationMessage">
                    <ng-template let-row #notificationMessage>
                        <img
                            class="level{{row.level}}"
                            src="../../../../../assets/shared/icons/notification-solid-checked-circle.svg"
                            alt="Exclamation icon"/>
                        <span [innerHTML]="row.message" (click)="navigate(row.url)"></span>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="date" label="date"></app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [selectAll]="actionDotsArray"
                    [cellTemplate]="notificationsActions">
                    <ng-template let-row #notificationsActions>
                        <div *ngIf="!row.editMode && !row.addMode">
                            <app-action-dots>
                                <button
                                    id="notifications-clear"
                                    (click)="clearSelectedNotification(row.id)">
                                    {{ 'notification_clear_selected' | myLbTranslate }}
                                </button>
                            </app-action-dots>
                        </div>
                    </ng-template>
                </app-column>
            </app-table>
        </div>
    </div>
</div>
