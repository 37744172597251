<ng-container *ngIf="!hasTier">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="security_certs_upgrade_title"
        messageString="security_certs_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-container *ngIf="isLoading else pageContent">
    <div class="page-loader"></div>
</ng-container>
<ng-template #pageContent>
    <ng-container *ngIf="!tableRows?.length else table">
        <app-alternative-state-page
            header="certificates"
            titleText="certificate_counter_none"
            messageHtml="{{ 'certificate_counter_none_message' | myLbTranslate }}"
            [mockTableHeaders]="['certificate_name', 'certificate_common_name', 'certificate_issuer', 'billing_expiry']"
            imagePath="assets/centralisedManagement/empty-state/certificates.svg">
        </app-alternative-state-page>
    </ng-container>
    <ng-template #table>
        <div class="component-main">
            <div class="content-space-between">
                <div class="content-left">
                    <h1 data-testid="component-title" id="component-title">{{ 'certificates' | myLbTranslate }}</h1>
                </div>
            </div>
            <app-table
                id="security-certificates"
                [data]="tableRows"
                [showPaginator]="false">
                <app-column [flexGrow]="1" prop="label" label="adc_name" [cellTemplate]="adcName">
                    <ng-template let-row #adcName>
                        <img
                            class="company-logo"
                            src="assets/centralisedManagement/icons/{{adcUtilsService.getVendorIconName(row.vendor)}}-colour.svg"
                            alt="{{adcUtilsService.getVendorTitle(row.vendor)}} Logo"
                            title="{{adcUtilsService.getVendorTitle(row.vendor)}}"
                            [class.disabled]="!permissionsLookup['certificate-more-details']"/>
                        <button
                            data-testid="security-certificates-details"
                            class="routerLink"
                            title="{{row.label}}"
                            [routerLink]="'/cm/security/certificates/details/' + row.id"
                            [disabled]="!permissionsLookup['certificate-more-details']">
                            {{ row.label }}
                        </button>
                    </ng-template>
                </app-column>
            </app-table>
        </div>
    </ng-template>
</ng-template>
